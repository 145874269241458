import SummaryOptionalExtra from './SummaryOptionalExtra';
import { useContext } from 'react';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';

const SummaryOptionalExtras = () => {
    const { products } = useContext(QuestionnaireContext);

    if (!products.content.length) return null;
    return (
        <div className="summary-optional-extras">
            {products.content.map(x => (
                <SummaryOptionalExtra
                    id={x.id}
                    key={x.id}
                    priceInPennies={x.price}
                    description={x.description}
                    title={x.name}
                    s3Key={x.imageS3Key}
                    alt={x.name}
                />
            ))}
        </div>
    );
};

// type SummaryOptionalExtrasProps = object;

export default SummaryOptionalExtras;
