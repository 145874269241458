import * as Accordion from '@radix-ui/react-accordion';

const AccordionTrigger = ({ children, isOpen, id }: AccordionTriggerProps) => {
    return (
        <Accordion.Header className="heading">
            <Accordion.Trigger className="trigger">
                <div className="flex-row align-center justify-between">
                    {children}
                    <div className="icon">
                        <i
                            className={`fal fa-${
                                isOpen === id ? 'chevron-up' : 'chevron-down'
                            } icon`}
                        />
                    </div>
                </div>
            </Accordion.Trigger>
        </Accordion.Header>
    );
};

interface AccordionTriggerProps {
    children: React.ReactNode;
    isOpen: string | undefined;
    id: string;
}

export default AccordionTrigger;
