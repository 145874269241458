import ProgressButton from '@components/questionnaire/buttons/ProgressButton';
import useCheckout from './hooks/useCheckout';
import { WizardContent } from '@components/questionnaire/WizardContentType';
import PageMeta from 'lib/src/components/pageMeta/PageMeta';

const Checkout = () => {
    const { content, isFetching, error } = useCheckout();

    return (
        <>
            <PageMeta title="Checkout" />

            <section className="checkout container flex-column gap-3">
                <WizardContent.Before content={content} isLoading={isFetching} error={error} />

                <p>todo: success/error pages here</p>
                <ProgressButton isNextDisabled={true} />
            </section>
        </>
    );
};

export default Checkout;
