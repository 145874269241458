import { ReactNode } from 'react';
import Heading2 from './Heading2';
import Heading3 from './Heading3';
import Heading4 from './Heading4';
import Heading5 from './Heading5';

export type HeadingProps = {
    children?: ReactNode;
    extraClasses?: string;
};

export const Heading = ({
    extraClasses = '',
    children,
    ...props
}: HeadingProps & React.ComponentProps<'h1'>) => {
    return (
        <h1 className={'heading-1 ' + extraClasses} {...props}>
            {children}
        </h1>
    );
};

export const Heading1 = Heading;

Heading.One = Heading1;
Heading.Two = Heading2;
Heading.Three = Heading3;
Heading.Four = Heading4;
Heading.Five = Heading5;

export default Heading;
