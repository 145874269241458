import QuestionnaireButtonRow from '@components/questionnaire/containers/QuestionnaireButtonRow';
import Form from 'lib/src/components/form/Form';
import ProgressButton from '@components/questionnaire/buttons/ProgressButton';
import useMeasurements from './hooks/useMeasurements';
import { MEASUREMENT_SHAPE } from 'lib/src/constants/enums';
import { MEASUREMENT_SHAPE_TEXT } from 'lib/src/constants/enumTextMappings';
import ImageButton from '@components/questionnaire/buttons/ImageButton';
import { MeasurementShape } from 'lib/src/types/shared/app/GardenStyle';
import MeasurementsInputs from './MeasurementsInputs';
import { WizardContent } from '@components/questionnaire/WizardContentType';
import FlowerBackground from '@components/misc/FlowerBackground';
import SaveAndContinue from '@pages/questionnaire/SaveAndContinue';
import PageMeta from 'lib/src/components/pageMeta/PageMeta';

const measurementShapes = [
    {
        id: MEASUREMENT_SHAPE.RECTANGLE as MeasurementShape,
        name: MEASUREMENT_SHAPE_TEXT[MEASUREMENT_SHAPE.RECTANGLE],
        src: '/images/measurements/rectangle.svg',
        alt: 'rectangle',
    },
    {
        id: MEASUREMENT_SHAPE.CIRCULAR as MeasurementShape,
        name: MEASUREMENT_SHAPE_TEXT[MEASUREMENT_SHAPE.CIRCULAR],
        src: '/images/measurements/circular.svg',
        alt: 'circular',
    },
    {
        id: MEASUREMENT_SHAPE.IRREGULAR as MeasurementShape,
        name: MEASUREMENT_SHAPE_TEXT[MEASUREMENT_SHAPE.IRREGULAR],
        src: '/images/measurements/irregular.svg',
        alt: 'irregular',
    },
];

const Measurements = () => {
    const {
        form,
        handleSubmit,
        handleChange,
        changeMeasurementShape,
        content: { content, isFetching, error },
        disableNext,
        measurementInputs,
    } = useMeasurements();

    const { shape } = form;

    return (
        <>
            <PageMeta title="Measurements" />

            <section className="measurements container flex-column gap-3">
                <Form onSubmit={handleSubmit} omitButtons>
                    <FlowerBackground />
                    <div className="flex-column gap-3">
                        <QuestionnaireButtonRow>
                            {measurementShapes.map(x => (
                                <ImageButton
                                    onClick={() => changeMeasurementShape(x.id)}
                                    imageClass="mixed-blend-none"
                                    text={x.name}
                                    key={x.id}
                                    src={x.src}
                                    isActive={shape === x.id}
                                />
                            ))}
                        </QuestionnaireButtonRow>
                        <MeasurementsInputs
                            {...form}
                            handleChange={handleChange}
                            measurementInputs={measurementInputs}
                        />
                        <WizardContent.Between
                            content={content}
                            isLoading={isFetching}
                            error={error}
                        />
                        <WizardContent.After
                            content={content}
                            isLoading={isFetching}
                            error={error}
                        />
                        <ProgressButton isNextDisabled={disableNext} />
                        <SaveAndContinue />
                        <WizardContent.Before
                            content={content}
                            isLoading={isFetching}
                            error={error}
                        />
                    </div>
                </Form>
            </section>
        </>
    );
};

export default Measurements;
