import useForm from 'lib/src/hooks/useForm';

import LoginForm from './LoginForm';
import { useMutation } from 'react-query';
import { LoginResponse, useApi } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { useAtom } from 'jotai';
import { jwtAtom, refreshTokenAtom } from 'lib/src/hooks/useIsLoggedIn';
import { useSelectedOrderGuid } from '@utils/atoms';

interface Props {
    onSuccess: () => void;
}

const LoginFormContainer: React.FC<Props> = ({ onSuccess }): JSX.Element => {
    const [, setJWT] = useAtom(jwtAtom);
    const [, setRefreshToken] = useAtom(refreshTokenAtom);

    const [selectedOrderGuid] = useSelectedOrderGuid();

    const api = useApi();

    const [formState, handleChange] = useForm({
        email: '',
        password: '',
    });
    const postLogin = async (postBody: LoginRequest) => {
        const data = await api.post<LoginRequest, LoginResponse>('auth/login', postBody);

        setJWT(data.token);
        setRefreshToken(data.refreshToken);
        return data;
    };
    const {
        mutate: handleSubmit,
        isLoading,
        error,
    } = useMutation<LoginResponse, APIError>(
        () => postLogin({ ...formState, orderGuid: selectedOrderGuid }),
        {
            onSuccess: onSuccess,
        },
    );

    return (
        <LoginForm
            handleChange={handleChange}
            formState={formState}
            handleSubmit={handleSubmit}
            isPosting={isLoading}
            error={error}
        />
    );
};

export default LoginFormContainer;

interface LoginRequest {
    email: string;
    password: string;
    orderGuid: string | null;
}
