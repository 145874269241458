import Heading from '@components/headings/Heading';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface FooterItem {
    name: string;
    href?: string;
    to?: string;
    faClass?: string;
}

export interface FooterSubSectionProps {
    name: string;
    footerItems: FooterItem[];
}

export interface CustomLinkProps {
    href?: string;
    to?: string;
    children: ReactNode;
}

// External sites require an anchor tag and not a link tag. Using this component as a work around
export const CustomLink = ({ href, to, children }: CustomLinkProps) =>
    !!to ? (
        <Link to={to} replace>
            {children}
        </Link>
    ) : (
        <a href={href}>{children}</a>
    );

const FooterSubSection = ({ name, footerItems }: FooterSubSectionProps) => {
    return (
        <div className="flex-column gap-1 footer-item">
            <Heading.Three>{name}</Heading.Three>
            {footerItems.map(({ href, faClass, name, to }, key) => {
                const isHref = !!to || !!href;
                const isFaClass = !!faClass;

                if (!isHref && !isFaClass) {
                    return <span key={key}>{name}</span>;
                }

                if (isHref && !isFaClass) {
                    return (
                        <CustomLink href={href} key={key} to={to}>
                            {name}
                        </CustomLink>
                    );
                }

                if (!isHref && isFaClass) {
                    return (
                        <span key={key}>
                            <i className={`fas ${faClass}`} />
                            {name}
                        </span>
                    );
                }

                return (
                    <CustomLink key={key} to={to} href={href}>
                        <i className={faClass}></i>
                        {name}
                    </CustomLink>
                );
            })}
        </div>
    );
};

export default FooterSubSection;
