import { WIZARD_CONTENT_POSITION, WIZARD_CONTENT_TYPE } from 'lib/src/constants/enums';
import TextImageSplit from './containers/TextImageSplit';
import InfoMessage from './containers/InfoMessage';
import Quote from './containers/Quote';
import Text from './containers/Text';
import { WizardContentItem, WizardContentType } from 'lib/src/types/shared/app/WizardContent';
import { FC, Key, memo, useCallback, useMemo } from 'react';
import Loading from '@components/loading/Loading';
import StepHeader from './containers/StepHeader';

export const WizardContentTypeMap: Record<WizardContentType, FC<WizardContentItem>> = {
    [WIZARD_CONTENT_TYPE.HEADING]: StepHeader.Wizard,
    [WIZARD_CONTENT_TYPE.SPLIT]: TextImageSplit.Wizard,
    [WIZARD_CONTENT_TYPE.INFO]: InfoMessage.Wizard,
    [WIZARD_CONTENT_TYPE.PULL_QUOTE]: Quote.Wizard,
    [WIZARD_CONTENT_TYPE.TEXT]: Text,
};

const InternalRenderContent = ({
    content,
    filterFunction = () => true,
    isLoading,
    error,
    contentType,
}: InternalRenderContentProps) => {
    const mapContentItem = useCallback(
        (x: WizardContentItem) => ({ Content: WizardContentTypeMap[x.type], props: x }),
        [],
    );

    const filteredContent = useMemo(() => {
        if (contentType === WIZARD_CONTENT_TYPE.HEADING) {
            const foundContent = content.find(
                (x, y) => filterFunction(x, y) && x.type === WIZARD_CONTENT_TYPE.HEADING,
            );

            return !foundContent ? [] : [mapContentItem(foundContent)];
        }

        return content
            .filter((x, y) => filterFunction(x, y) && x.type !== WIZARD_CONTENT_TYPE.HEADING)
            .sort((x, y) => x.type - y.type)
            .map(mapContentItem);
    }, [content, contentType, filterFunction, mapContentItem]);

    return (
        <>
            {error && <p>error</p>}
            {!isLoading ? (
                filteredContent.map(({ Content, props }) => <Content key={props.id} {...props} />)
            ) : (
                <Loading size="large" />
            )}
        </>
    );
};

export const WizardContent = {
    Header: memo(({ content, isLoading, error }: RenderContentProps) => (
        <InternalRenderContent
            isLoading={isLoading}
            error={error}
            content={content}
            contentType={WIZARD_CONTENT_TYPE.HEADING}
        />
    )),
    Before: memo(({ content, isLoading, error }: RenderContentProps) => (
        <InternalRenderContent
            isLoading={isLoading}
            error={error}
            content={content}
            filterFunction={item => item.position === WIZARD_CONTENT_POSITION.BEFORE}
        />
    )),
    Between: memo(({ content, isLoading, error }: RenderContentProps) => (
        <InternalRenderContent
            isLoading={isLoading}
            error={error}
            content={content}
            filterFunction={item => item.position === WIZARD_CONTENT_POSITION.BETWEEN}
        />
    )),
    After: memo(({ content, isLoading, error }: RenderContentProps) => (
        <InternalRenderContent
            isLoading={isLoading}
            error={error}
            content={content}
            filterFunction={item => item.position === WIZARD_CONTENT_POSITION.AFTER}
        />
    )),
};

type InternalRenderContentProps = RenderContentProps & {
    filterFunction?: (item: WizardContentItem, y: Key) => boolean;
    contentType?: WizardContentType;
};

export type RenderContentProps = {
    content: WizardContentItem[];
    isLoading: boolean;
    error: unknown;
};
