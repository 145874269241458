export const SOIL_TYPES = {
    CLAY: 1,
    SOIL: 2,
    SANDY: 3,
} as const;

export const SUNLIGHT = {
    MORNING: 1,
    AFTERNOON: 2,
    EVENING: 3,
    ALL_DAY: 4,
    NEVER: 5,
} as const;

export const SUNLIGHT_VALUES = {
    [SUNLIGHT.MORNING]: 'Morning',
    [SUNLIGHT.AFTERNOON]: 'Afternoon',
    [SUNLIGHT.EVENING]: 'Evening',
    [SUNLIGHT.ALL_DAY]: 'All day',
    [SUNLIGHT.NEVER]: 'Never',
};

export const BED = {
    FULL_BED: 1,
    SOIL_BED: 2,
    NO_BEDS: 3,
} as const;

export const FULL_BED = {
    FULL_PLANTS_AND_WEEDS: 1,
    LOADS_OF_PLANTS: 2,
    LOADS_OF_WEEDS: 3,
    BITS_OF_WEEDS: 4,
} as const;

export const MEASUREMENT_UNIT = {
    MILLIMETRES: 1,
    CENTIMETRES: 2,
    METRES: 3,
    INCHES: 4,
    FEET: 5,
    YARDS: 6,
} as const;

export const MEASUREMENT_SHAPE = {
    RECTANGLE: 1,
    CIRCULAR: 2,
    IRREGULAR: 3,
    // UPLOAD: 4,
} as const;

export const WIZARD_CONTENT_TYPE = {
    HEADING: 1,
    INFO: 2,
    PULL_QUOTE: 3,
    SPLIT: 4,
    TEXT: 5,
} as const;

export const WIZARD_STAGE = {
    READY: 1,
    SUNLIGHT: 2,
    SOIL: 3,
    STYLE: 4,
    MEASURE: 5,
    CONTEXT: 6,
    ANYTHING_ELSE: 7,
    SUMMARY: 8,
    CHECKOUT: 9,
} as const;

export const BED_SHAPE = {
    RECTANGLE: 1,
    CIRCLE: 2,
    IRREGULAR: 3,
} as const;

export const WIZARD_CONTENT_POSITION = {
    BEFORE: 0,
    BETWEEN: 1,
    AFTER: 2,
} as const;
