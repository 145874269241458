import { ComponentProps, FC } from 'react';

const DescriptionRadioButton: FC<DescriptionRadioButtonProps> = ({
    description,
    title,
    className,
    type = 'button',
    src = '/images/Pots.webp',
    alt = 'pots',
    onClick,
    ...props
}) => {
    return (
        <button
            className={`questionnaire-description-button flex-column align-center ${className}`}
            type={type}
            onClick={onClick}
            {...props}
        >
            {!!src && <img src={src} alt={alt} width={330} height={330}></img>}
            <div className="btn-title">{title}</div>
            <div className="btn-description">{description}</div>
        </button>
    );
};

export type DescriptionRadioButtonProps = Omit<ComponentProps<'button'>, 'children'> & {
    title: string;
    description: string;
    src?: string | null;
    alt?: string;
    onClick: () => void;
};

export default DescriptionRadioButton;
