import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useContext } from 'react';
import { WIZARD_STAGE } from 'lib/src/constants/enums';

const useCheckout = () => {
    const {
        wizardContent: { content, isFetching, error },
        order,
    } = useContext(QuestionnaireContext);

    return {
        content: content[WIZARD_STAGE.CHECKOUT],
        isFetching,
        error,
        order,
    };
};
export default useCheckout;
