import useForm from 'lib/src/hooks/useForm';
import { useApi } from 'lib/src/utils/api';
import { Order } from 'lib/src/types/shared/app/Order';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useContext, useMemo } from 'react';
import { WIZARD_STAGE } from 'lib/src/constants/enums';
import { useQuery } from 'react-query';
import { Footnote } from 'lib/src/types/shared/app/GardenStyle';
import { APIError } from 'lib/src/types/APIError';
import { CacheKeys } from '../../../../../types/query/CacheKeys';

interface PostYourBedRequest {
    imageS3Keys: string[];
    footnoteIDs: number[];
    notes: string;
}

const useYourBed = () => {
    const {
        isPosting,
        order,
        bed,
        postStep,
        wizardContent: { content, isFetching, error },
    } = useContext(QuestionnaireContext);
    const api = useApi();

    const [form, handleChange] = useForm<PostYourBedRequest>({
        imageS3Keys: bed?.images.map(x => x.s3Key) ?? [],
        footnoteIDs: bed?.footnotes.map(x => x.footnoteID) ?? [],
        notes: bed?.notes ?? '',
    });

    const handleSubmit = () => {
        const postReady = () =>
            api.post<PostYourBedRequest, Order>(
                `orders/${order?.guid}/beds/${bed?.id}/stage/yourbed`,
                form,
            );
        postStep(postReady);
    };

    const { data: footnotes } = useQuery<Footnote[], APIError>(
        [CacheKeys.Footnotes],
        () => api.get<Footnote[]>('footnotes'),
        { refetchOnWindowFocus: false },
    );

    const options = useMemo(
        () => footnotes?.map(({ text, id }) => ({ label: text, value: id })) ?? [],
        [footnotes],
    );

    return {
        form,
        handleSubmit,
        handleChange,
        content: content[WIZARD_STAGE.CONTEXT],
        isPosting,
        isFetching,
        error,
        options,
    };
};

export default useYourBed;
