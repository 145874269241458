import development from './dev.config';
import staging from './staging.config';
import production from './production.config';

const configs: Record<string, Config> = {
    development,
    staging,
    production,
};

export interface Config {
    API_URL: string;
    S3_URL: string;
}

const env = (import.meta.env.MODE || 'development') as 'development' | 'staging' | 'production';
export const { API_URL, S3_URL } = configs[env];
