import { ObjectValues } from '../types/helpers';

class Currency {
    private value: string;
    private options?: CurrencyOptions;

    static isValid(value: string, options?: CurrencyOptions): string | null {
        const parsed = Number(value);
        if (isNaN(parsed)) return 'Please enter a numeric value';
        if (parsed !== Number(parsed.toFixed(2)) && !options?.allowPrecision)
            return 'Please enter a value with up to 2 decimal places';
        return null;
    }
    static display(value: string | number, options?: DisplayOptions): string {
        if (!!options?.fixedPoint) {
            return `${options?.currency ?? DEFAULT_CURRENCY}${Number(value).toLocaleString(
                'en-US',
                { minimumFractionDigits: options.fixedPoint },
            )}`;
        }
        return `${options?.currency ?? DEFAULT_CURRENCY}${Number(value).toLocaleString('en-US')}`;
    }

    static fromPennies(value: string | number, options?: CurrencyOptions) {
        if (!value) {
            return new Currency(0);
        }
        return new Currency(+value.toString().replace(/[^0-9.-]+/g, '') / 100, options);
    }

    constructor(value: string | number, options?: CurrencyOptions) {
        this.value = value.toString().replace(/[^0-9.-]+/g, '');
        this.options = options;
    }

    getValue(): string {
        return this.value;
    }
    getValueAsNumber(): number {
        return +this.value;
    }
    exportAsPennies(): number {
        return Number(this.value.replace(/[^0-9.-]+/g, '')) * 100;
    }

    isValid(): string | null {
        return Currency.isValid(this.value, this.options);
    }

    display(options?: DisplayOptions): string {
        return Currency.display(this.value, options);
    }
}

export const CURRENCY_SYMBOLS = {
    USD: '$', // United States Dollar
    EUR: '€', // Euro
    JPY: '¥', // Japanese Yen
    GBP: '£', // British Pound Sterling
    AUD: 'A$', // Australian Dollar
    CAD: 'C$', // Canadian Dollar
    CHF: 'CHF', // Swiss Franc
    CNY: '¥', // Chinese Yuan
    SEK: 'kr', // Swedish Krona
    NZD: 'NZ$', // New Zealand Dollar
    MXN: '$', // Mexican Peso
    SGD: 'S$', // Singapore Dollar
    HKD: 'HK$', // Hong Kong Dollar
    NOK: 'kr', // Norwegian Krone
    KRW: '₩', // South Korean Won
    TRY: '₺', // Turkish Lira
    RUB: '₽', // Russian Ruble
    INR: '₹', // Indian Rupee
    BRL: 'R$', // Brazilian Real
    ZAR: 'R', // South African Rand
    SAR: '﷼', // Saudi Riyal
    AED: 'د.إ', // United Arab Emirates Dirham
} as const;

type CurrencySymbol = ObjectValues<typeof CURRENCY_SYMBOLS>;
const DEFAULT_CURRENCY: CurrencySymbol = CURRENCY_SYMBOLS.GBP;

interface DisplayOptions {
    currency?: CurrencySymbol;
    fixedPoint?: number;
}

interface CurrencyOptions {
    allowPrecision?: boolean;
}

export const penceToPounds = (pence?: number | null) => {
    if (pence === undefined || pence === null) return null;

    const pounds = pence / 100;

    return pounds.toFixed(2);
};

export default Currency;
