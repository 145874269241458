import Form from '../form/Form';
import Description from '../typography/Description';
import Modal from './Modal';
import { APIError } from '../../types/APIError';

interface Props {
    closeModal: () => void;
    onSuccess?: () => void;
    handleSubmit: () => void;
    error?: string | null;
    apiError?: APIError | null;
    isPosting?: boolean;
    postSuccess?: boolean;
    title?: string;
    description?: string;
    submitText?: string;
}

const ConfirmModal: React.FC<Props> = ({
    closeModal,
    handleSubmit,
    onSuccess,
    error,
    postSuccess,
    isPosting,
    title = 'Confirm',
    description = 'Are you sure?',
    apiError = null,
    submitText = 'Confirm',
}) => {
    return (
        <Modal onClose={closeModal} title={title} size="small">
            <Description>{description}</Description>
            <Form
                onSubmit={handleSubmit}
                onCancel={closeModal}
                isPosting={isPosting}
                error={error}
                apiError={apiError}
                postSuccess={postSuccess}
                onSuccess={onSuccess}
                submitText={submitText}
            />
        </Modal>
    );
};

export default ConfirmModal;
