import { useEffect } from 'react';

export const useScrollToTop = (options: any) => {
    useEffect(() => {
        const element = document.getElementById('web-body');
        if (element) {
            element.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [options]);
};
