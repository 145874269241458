import Form from 'lib/src/components/form/Form';
import ProgressButton from '@components/questionnaire/buttons/ProgressButton';
import useStyle from './hooks/useStyle';
import GardenStyleOption from './GardenStyleOption';
import { WizardContent } from '@components/questionnaire/WizardContentType';
import FlowerBackground from '@components/misc/FlowerBackground';
import SaveAndContinue from '@pages/questionnaire/SaveAndContinue';
import PageMeta from 'lib/src/components/pageMeta/PageMeta';

const Style = () => {
    const {
        form: { gardenStyleID },
        handleSubmit,
        changeStyle,
        content,
        gardenStyles,
        isFetching,
        error,
        disableNext,
    } = useStyle();

    return (
        <>
            <PageMeta title="Style" />
            <section className="style container flex-column gap-3">
                <WizardContent.Before content={content} isLoading={isFetching} error={error} />
                <Form onSubmit={handleSubmit} omitButtons>
                    <FlowerBackground />
                    <div className="flex-column gap-3">
                        <div className="style-container">
                            {gardenStyles.styles.map(style => (
                                <GardenStyleOption
                                    key={style.id}
                                    gardenStyle={style}
                                    activeStyleId={gardenStyleID}
                                    changeStyle={changeStyle}
                                />
                            ))}
                        </div>
                        <WizardContent.After
                            content={content}
                            isLoading={isFetching}
                            error={error}
                        />
                        <WizardContent.Between
                            content={content}
                            isLoading={isFetching}
                            error={error}
                        />
                        <ProgressButton isNextDisabled={disableNext} />
                        <SaveAndContinue />
                    </div>
                </Form>
            </section>
        </>
    );
};

export default Style;
