import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { CSSProperties, ReactNode, useContext, useMemo } from 'react';
import { WizardStage } from 'lib/src/types/shared/app/WizardContent';
import useWindowSize from 'lib/src/hooks/useWindowSize';
import Ready from './Ready/Ready';
import Sunlight from './Sunlight/Sunlight';
import Soil from './Soil/Soil';
import Style from './Style/Style';
import Measurements from './Measurements/Measurements';
import YourBed from './YourBed/YourBed';
import AnythingElse from './AnythingElse/AnythingElse';
import Summary from './Summary/Summary';
import Checkout from './Checkout/Checkout';
import LinkItems from './StepComponents/LinkItems';
import { WIZARD_STAGE } from 'lib/src/constants/enums';
import { reverseMapper } from 'lib/src/utils/generic';
import { Link, useHistory } from 'react-router-dom';

export interface StepProps {
    step: WizardStage;
    children: ReactNode;
}

export interface OtherProps {
    isActive: boolean;
    canMoveToStep: boolean;
    completed?: boolean;
    isLastCompleted?: boolean;
    isNextCompleted?: boolean;
    isFirstStep?: boolean;
    isCurrentStage?: boolean;
    style?: CSSProperties;
}

const getStepExtraClass = (
    isActive: boolean,
    completed: boolean,
    isLastCompleted: boolean,
    isCurrentStage: boolean,
    isFirstStep: boolean,
) => {
    if (isActive) return 'active';
    if (completed && !isActive) return 'completed';
    if (!isFirstStep && (isCurrentStage || isLastCompleted)) return 'completed-after';
    return '';
};

const Step = ({
    children,
    isActive = false,
    completed = false,
    isLastCompleted = false,
    isNextCompleted = false,
    isCurrentStage = false,
    isFirstStep = false,
    canMoveToStep = false,
    step,
    style,
    ...otherProps
}: StepProps & OtherProps) => {
    const { setStep } = useContext(QuestionnaireContext);

    const activeType =
        (completed && !isLastCompleted) ||
        (!isNextCompleted && isActive) ||
        (isLastCompleted && !isActive)
            ? 'full'
            : isFirstStep
            ? ''
            : 'partial';

    const extraClass = getStepExtraClass(
        isActive,
        completed,
        isLastCompleted,
        isCurrentStage,
        isFirstStep,
    );

    const onClick = () => setStep(step);

    const {
        location: { pathname },
    } = useHistory();

    return (
        <Link
            to={canMoveToStep ? '/questionnaire' : pathname}
            {...(canMoveToStep && { onClick })}
            {...otherProps}
            style={style}
            className={`step-background ${canMoveToStep ? 'cursor-pointer' : 'cursor-not-allowed'}`}
        >
            {isActive && <div className={`active-${activeType}`}></div>}
            <div className={`step text-center ${extraClass}`}>
                <span>{children}</span>
            </div>
        </Link>
    );
};

const stepNames = reverseMapper<WizardStage>(WIZARD_STAGE, 'sentence');

const Steps = () => {
    const {
        location: { pathname },
    } = useHistory();

    const { step: currentStep, bed } = useContext(QuestionnaireContext);

    const items: Readonly<StepProps[]> = useMemo(
        () =>
            Object.entries(stepNames).map(([key, name]) => ({
                children: name,
                step: +key as WizardStage,
                isActive: +key === (currentStep ?? 1),
            })),
        [currentStep],
    );

    const width = 100 / (!!items.length ? items.length : 1);

    const bedStage = bed?.currentStage ?? 1;
    const currentStepFilled = currentStep ?? 1;

    const { width: windowWidth } = useWindowSize();

    return (
        <div className="width-12 steps-container">
            <div className="flex-row width-12 justify-center  gap-2">
                <div className="flex-row steps justify-between flex-wrap">
                    {items.map(({ children, step }, key) => {
                        const isActive = pathname.includes('questionnaire')
                            ? currentStepFilled === step
                            : false;
                        const completed = step < bedStage;
                        const shouldRenderFullName =
                            windowWidth > 960 || (isActive && windowWidth > 550);
                        const isLastCompleted = bedStage === step && step >= currentStepFilled;
                        const isNextCompleted = bedStage >= step;
                        const isFirstStep = step === 1;
                        const isCurrentStage = bedStage === step;
                        const canMoveToStep = step <= bedStage;

                        return (
                            <Step
                                step={step}
                                completed={completed}
                                isActive={isActive}
                                canMoveToStep={canMoveToStep}
                                isLastCompleted={isLastCompleted}
                                isNextCompleted={isNextCompleted}
                                isFirstStep={isFirstStep}
                                isCurrentStage={isCurrentStage}
                                key={key}
                                style={{ width: `${width}%` }}
                            >
                                {shouldRenderFullName ? children : `${step}.`}
                            </Step>
                        );
                    })}
                </div>
                <LinkItems />
            </div>
        </div>
    );
};

Steps.Ready = Ready;
Steps.Sunlight = Sunlight;
Steps.Soil = Soil;
Steps.Style = Style;
Steps.Measurements = Measurements;
Steps.YourBed = YourBed;
Steps.AnythingElse = AnythingElse;
Steps.Summary = Summary;
Steps.Checkout = Checkout;

export default Steps;
