import useDeleteBed from './hooks/useDeleteBed';

import AccordionContent from '@components/accordions/AccordionContent';
import AccordionTrigger from '@components/accordions/AccordionTrigger';
import * as Accordion from '@radix-ui/react-accordion';
import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';

import { Bed } from 'lib/src/types/shared/app/Bed';
import { ProductOrder } from 'lib/src/types/shared/app/Product';
import {
    BED_SHAPE_VALUES,
    BED_VALUES,
    MEASUREMENT_UNIT_MAPPINGS,
    SOIL_VALUES,
    SUNLIGHT_VALUES,
} from 'lib/src/constants/enumTextMappings';
import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import useSummaryAccordionItem from './hooks/useSummaryAccordionItem';
import { BED_SHAPE } from 'lib/src/constants/enums';
import { getBedName } from 'lib/src/shared/helpers/bed';

const SummaryAccordionItem = ({ item, isOpen, bedNumber }: SummaryAccordionItemProps) => {
    const {
        id,
        bedType,
        style,
        notes,
        sunlight,
        soilType,
        images,
        shape,
        length,
        width,
        diameter,
        measurementUnit,
    } = item;
    const { handleSubmit, isPosting, error, setShowDeleteBed, showDeleteBedModal } =
        useDeleteBed(id);
    const { total, handleEditBed } = useSummaryAccordionItem(item);

    const bedName = getBedName(
        { diameter, length, width, type: shape, unit: measurementUnit },
        style?.name,
    );

    return (
        <Accordion.Item className="accordion-item" value={id.toString()}>
            <AccordionTrigger id={id.toString()} isOpen={isOpen}>
                <div className="flex flex-row justify-between">
                    <p className="bed-number">{bedName}</p>
                    <p className="total-cost">{total}</p>
                </div>
            </AccordionTrigger>

            <AccordionContent>
                {error && (
                    <p className="form-error">
                        Error: {error.response.status} - {error.message}
                    </p>
                )}
                <div className="accordion-grid">
                    <div className="accordion-grid-item">
                        <p className="accordion-grid-item-title">Ready to plant</p>
                        <p className="accordion-grid-item-value">
                            {bedType ? BED_VALUES[bedType] : 'N/A'}
                        </p>
                    </div>
                    <div className="accordion-grid-item">
                        <p className="accordion-grid-item-title">Sunlight</p>
                        <p className="accordion-grid-item-value">
                            {sunlight ? SUNLIGHT_VALUES[sunlight] : 'N/A'}
                        </p>
                    </div>
                    <div className="accordion-grid-item">
                        <p className="accordion-grid-item-title">Your soil</p>
                        <p className="accordion-grid-item-value">
                            {soilType ? SOIL_VALUES[soilType] : 'N/A'}
                        </p>
                    </div>
                    <div className="accordion-grid-item">
                        <p className="accordion-grid-item-title">Your style</p>
                        <p className="accordion-grid-item-value">{style?.name ?? ''}</p>
                    </div>
                    <div className="accordion-grid-item">
                        <p className="accordion-grid-item-title">Shape</p>
                        <p className="accordion-grid-item-value">
                            {shape ? BED_SHAPE_VALUES[shape] : 'N/A'}
                        </p>
                    </div>
                    {shape === BED_SHAPE.CIRCLE && (
                        <>
                            <div className="accordion-grid-item">
                                <p className="accordion-grid-item-title">Diameter</p>
                                <p className="accordion-grid-item-value">
                                    {diameter}
                                    {MEASUREMENT_UNIT_MAPPINGS[item.measurementUnit ?? 1]}
                                </p>
                            </div>
                            <div />
                            <div />
                        </>
                    )}
                    {(shape === BED_SHAPE.IRREGULAR || shape === BED_SHAPE.RECTANGLE) && (
                        <>
                            <div className="accordion-grid-item">
                                <p className="accordion-grid-item-title">Length</p>
                                <p className="accordion-grid-item-value">
                                    {length}
                                    {MEASUREMENT_UNIT_MAPPINGS[item.measurementUnit ?? 1]}
                                </p>
                            </div>
                            <div className="accordion-grid-item">
                                <p className="accordion-grid-item-title">Width</p>
                                <p className="accordion-grid-item-value">
                                    {width}
                                    {MEASUREMENT_UNIT_MAPPINGS[item.measurementUnit ?? 1]}
                                </p>
                            </div>
                            <div />
                        </>
                    )}
                    <div className="accordion-grid-item">
                        <p className="accordion-grid-item-title">Your bed</p>
                        <p className="accordion-grid-item-value">{`${images.length} image${
                            images.length > 1 ? 's' : ''
                        } added`}</p>
                        <div className="accordion-grid-item checkbox-container">
                            {item.footnotes.map(x => (
                                <p className="accordion-grid-item-checkbox" key={x.footnoteID}>
                                    <i className="far fa-check"></i>
                                    {x.text}
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className="accordion-grid-item">
                        <p className="accordion-grid-item-title">Other notes</p>
                        <p className="accordion-grid-item-value">
                            {!!notes?.length ? notes : 'N/A'}
                        </p>
                    </div>
                </div>

                <ButtonRow alignment="left">
                    <ActionButton onClick={handleEditBed} isPosting={isPosting} type="button">
                        Make changes
                    </ActionButton>
                    <ActionButton
                        type="button"
                        onClick={() => setShowDeleteBed(true)}
                        isPosting={isPosting}
                        source="negative"
                    >
                        Remove bed
                    </ActionButton>
                </ButtonRow>

                {showDeleteBedModal && (
                    <ConfirmModal
                        closeModal={() => setShowDeleteBed(false)}
                        onSuccess={() => setShowDeleteBed(false)}
                        title="Confirm Removal"
                        submitText="Remove"
                        description="Are you sure you want to remove this bed from your order? This will be permanently lost."
                        handleSubmit={handleSubmit}
                    />
                )}
            </AccordionContent>
        </Accordion.Item>
    );
};

interface SummaryAccordionItemProps {
    item: Bed;
    products: ProductOrder[];
    isOpen: string | undefined;
    bedNumber: number;
}

export default SummaryAccordionItem;
