import Routes from './routes';
import useDarkMode from 'lib/src/hooks/useDarkMode';
import Footer from '@components/footer/Footer';
import Aria from 'lib/src/components/accessibility/Aria';
import { useMemo } from 'react';

const App: React.FC = () => {
    const [darkMode] = useDarkMode();

    const className = useMemo(() => {
        const location = window.location.pathname;
        if (location.startsWith('/questionnaire')) {
            return 'questionnaire';
        }
        return '';
    }, []);
    return (
        <div id="web-body" className={`body ${className}`} data-theme={darkMode ? 'dark' : 'light'}>
            <Routes />
            <Footer />
            <Aria />
        </div>
    );
};

export default App;
