import { useApi } from 'lib/src/utils/api';
import { useQuery } from 'react-query';

import { Heading1 } from '@components/headings/Heading';
import { CacheKeys } from '../../types/query/CacheKeys';
import { Order } from 'lib/src/types/shared/app/Order';
import { APIError } from 'lib/src/types/APIError';
import dayjs from 'dayjs';
import { penceToPounds } from 'lib/src/utils/currency';
import Loading from '@components/loading/Loading';

function orderSort(a: Order, b: Order) {
    if (a.createdOn > b.createdOn) {
        return -1;
    }
    if (a.createdOn < b.createdOn) {
        return 1;
    }
    return 0;
}

const Orders = () => {
    const api = useApi();
    const { data: orders, isFetching } = useQuery<Order[], APIError>(CacheKeys.Orders, () =>
        api.get<Order[]>('orders'),
    );

    return (
        <div className="container">
            <Heading1>Orders</Heading1>

            <div className="orders-container">
                {isFetching ? (
                    <div className="flex-row justify-center align-center">
                        <Loading size="large" />
                    </div>
                ) : (
                    orders?.sort(orderSort).map(order => (
                        <a
                            key={order.id}
                            className="order-card flex-row"
                            href={`/account/orders/${order.guid}`}
                        >
                            <div className="flex-column flex-6">
                                <div className="flex-row">
                                    <p>
                                        <strong>Order Reference:</strong> {order.reference}
                                    </p>
                                </div>

                                <div className="flex-row">
                                    <p>
                                        <strong>Order Date:</strong>{' '}
                                        {dayjs(order.createdOn).format('DD/MM/YYYY - HH:mma')}
                                    </p>
                                </div>

                                <div className="flex-row">
                                    <p>
                                        <strong>Order Status:</strong>{' '}
                                        {order.isCompleted ? 'Completed' : 'In Progress'}
                                    </p>
                                </div>
                            </div>

                            <div className="flex-column">
                                <div className="flex-row">
                                    <p>
                                        <strong>Number of beds:</strong>{' '}
                                        {order.beds.length || 'N/A'}
                                    </p>
                                </div>

                                <div className="flex-row">
                                    <p>
                                        <strong>Additional products:</strong>{' '}
                                        {order.products.length || 'N/A'}
                                    </p>
                                </div>

                                <div className="flex-row">
                                    <p>
                                        <strong>Total:</strong> £{penceToPounds(order.total)}
                                    </p>
                                </div>
                            </div>
                        </a>
                    ))
                )}
            </div>
        </div>
    );
};

export default Orders;
