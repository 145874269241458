import Steps from '@components/questionnaire/steps/Steps';
import React, { useContext } from 'react';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { WIZARD_STAGE } from 'lib/src/constants/enums';
import { WizardStage } from 'lib/src/types/shared/app/WizardContent';
import Loading from '@components/loading/Loading';
import { useScrollToTop } from '@utils/generic';
import { WizardContent } from '@components/questionnaire/WizardContentType';
import InfoBanner from '@components/misc/InfoBanner';

const stepComponents: Record<WizardStage, React.FC> = {
    [WIZARD_STAGE.READY]: Steps.Ready,
    [WIZARD_STAGE.SUNLIGHT]: Steps.Sunlight,
    [WIZARD_STAGE.SOIL]: Steps.Soil,
    [WIZARD_STAGE.STYLE]: Steps.Style,
    [WIZARD_STAGE.MEASURE]: Steps.Measurements,
    [WIZARD_STAGE.CONTEXT]: Steps.YourBed,
    [WIZARD_STAGE.ANYTHING_ELSE]: Steps.AnythingElse,
    [WIZARD_STAGE.SUMMARY]: Steps.Summary,
    [WIZARD_STAGE.CHECKOUT]: Steps.Checkout,
};

const Questionnaire = () => {
    const {
        step,
        isFetching,
        wizardContent: { content: allContent, isFetching: isContentFetching, error: contentError },
        order,
    } = useContext(QuestionnaireContext);
    const StepComponent = step ? stepComponents[step] : null;

    const content = allContent[step ?? WIZARD_STAGE.READY];

    useScrollToTop(step);

    return (
        <div id="questionnaire">
            <Steps />
            <WizardContent.Header
                isLoading={isContentFetching}
                error={contentError}
                content={content}
            />
            <main className="container width-12">
                <div>
                    {isFetching && !order && <Loading size="large" />}
                    {StepComponent && <StepComponent />}

                    <InfoBanner
                        text={
                            <>
                                <i>
                                    Stuck on this question? Email us at{' '}
                                    <a
                                        href="mailto:wonder@holdenclough.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        wonder@holdenclough.com
                                    </a>
                                </i>
                            </>
                        }
                    />
                </div>
            </main>
        </div>
    );
};

export default Questionnaire;
