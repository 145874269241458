import { useApi } from 'lib/src/utils/api';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { APIError } from 'lib/src/types/APIError';
import { Order as OrderType } from 'lib/src/types/shared/app/Order';
import { CacheKeys } from '../../types/query/CacheKeys';

import Loading from '@components/loading/Loading';
import Heading2 from '@components/headings/Heading2';

import dayjs from 'dayjs';
import { penceToPounds } from 'lib/src/utils/currency';
import Heading4 from '@components/headings/Heading4';
import BedCard from './BedCard';
import useAddBedImages from './hooks/useAddBedImages';
import FlexWrapper from 'lib/src/components/wrapper/FlexWrapper';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import { useSelectedBedID, useSelectedOrderGuid, useSelectedStep } from '@utils/atoms';

const Order = () => {
    const api = useApi();
    const history = useHistory();

    const params = useParams<{ id: string }>();

    const { data: order, isLoading } = useQuery<OrderType, APIError>(CacheKeys.Orders, () =>
        api.get<OrderType>(`orders/${params?.id}`),
    );

    const optionalExtrasTotal = order?.products.reduce((acc, product) => {
        return acc + product.price;
    }, 0);

    const addBedImages = useAddBedImages(order?.guid ?? '');

    const [, setGuid] = useSelectedOrderGuid();
    const [, setBedID] = useSelectedBedID();
    const [, setStep] = useSelectedStep();

    const handleCompleteOrder = () => {
        setGuid(order?.guid);

        const bed = order?.beds[0];

        if (bed) {
            setStep(bed.currentStage);
            setBedID(bed.id);
        }

        history.push('/questionnaire');
    };

    return (
        <div className="container">
            <Heading2>Order - {params.id}</Heading2>

            {isLoading ? (
                <div className="flex-row justify-center align-center">
                    <Loading size="large" />
                </div>
            ) : (
                <>
                    <FlexWrapper>
                        <div className="flex-4">
                            <Heading4>
                                <strong>Date</strong>
                            </Heading4>
                            <p>{dayjs(order?.createdOn).format('DD/MM/YYYY - HH:mma')}</p>
                        </div>

                        <div className="flex-4">
                            <Heading4>
                                <strong>Status</strong>
                            </Heading4>
                            <p>{order?.isCompleted ? 'Completed' : 'In Progress'}</p>
                        </div>

                        <div className="flex-4">
                            <Heading4>
                                <strong>Total</strong>
                            </Heading4>{' '}
                            <p>£{penceToPounds(order?.total)}</p>
                        </div>
                    </FlexWrapper>

                    <br />

                    <div className="flex-row">
                        <div className="flex-4">
                            <Heading4>
                                <strong>Address</strong>
                            </Heading4>
                            {!!order?.addressLine1 ? (
                                <p>
                                    {order?.addressLine1},<br />
                                    {order?.addressLine2},<br />
                                    {order?.city},<br />
                                    {order?.postcode}
                                </p>
                            ) : (
                                <p>No address provided</p>
                            )}
                        </div>

                        <div className="flex-4">
                            <Heading4>
                                <strong>Additional Products</strong>
                            </Heading4>
                            {order?.products.length ? (
                                order.products.map(product => (
                                    <p>
                                        {product.name} - £{penceToPounds(product.price)}
                                    </p>
                                ))
                            ) : (
                                <p>No additional products</p>
                            )}

                            <p>
                                <strong>Total</strong> - £{penceToPounds(optionalExtrasTotal)}
                            </p>
                        </div>
                    </div>

                    <br />

                    <FlexWrapper>
                        <div className="flex-12">
                            <Heading4>
                                <strong>Beds</strong>
                            </Heading4>

                            {order?.beds.map((bed, index) => (
                                <BedCard
                                    key={bed.id}
                                    bed={bed}
                                    bedNumber={index + 1}
                                    addBedImages={addBedImages}
                                />
                            ))}
                        </div>
                    </FlexWrapper>

                    {!order?.isCompleted && (
                        <ButtonRow>
                            <ActionButton onClick={handleCompleteOrder}>
                                Complete order
                            </ActionButton>
                        </ButtonRow>
                    )}
                </>
            )}
        </div>
    );
};

export default Order;
