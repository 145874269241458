import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { Bed } from 'lib/src/types/shared/app/Bed';
import Currency from 'lib/src/utils/currency';
import { useMemo, useContext } from 'react';

const useSummaryAccordionItem = (bed: Bed) => {
    const total = useMemo(
        () => Currency.fromPennies(bed?.total ?? 0).display({ fixedPoint: 2 }),
        [bed?.total],
    );

    const { changeBed } = useContext(QuestionnaireContext);

    const handleEditBed = () => changeBed(bed.id);

    return { total, handleEditBed, changeBed };
};

export default useSummaryAccordionItem;
