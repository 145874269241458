import jwtDecode from 'jwt-decode';
import { jwtAtom, refreshTokenAtom } from '../hooks/useIsLoggedIn';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

interface JWT {
    nbf: number;
    exp: number;
}

export function validateJWT(token: string | null): boolean {
    try {
        if (!token) return false;
        return !!token;
    } catch {
        return false;
    }
}
export const useJwt = () => {
    const [jwt] = useAtom(jwtAtom);
    return jwt;
};

export const useRefreshToken = () => {
    const [refreshToken] = useAtom(refreshTokenAtom);
    return refreshToken;
};
export const useLogout = () => {
    const history = useHistory();
    return useCallback(
        (redirectPath = '/') => {
            localStorage.clear();
            history.push(redirectPath);
        },
        [history],
    );
};

export const useDecodedJwtToken = <T>(): (JWT & T) | null => {
    const jwtToken = useJwt();
    const isValid = validateJWT(jwtToken);
    if (!jwtToken || !isValid) return null;

    return jwtDecode<JWT & T>(jwtToken);
};
