import TextImageSplit from '@components/questionnaire/containers/TextImageSplit';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import useAddAnotherBed from './hooks/useAddAnotherBed';

type AddBedButtonProps = {
    isPosting: boolean;
    handleSubmit: () => void;
};

const AddBedButton = ({ isPosting, handleSubmit }: AddBedButtonProps) => (
    <ButtonRow alignment="left">
        <ActionButton
            onClick={() => handleSubmit()}
            type="button"
            rightIcon="plus"
            isPosting={isPosting}
        >
            Add another bed
        </ActionButton>
    </ButtonRow>
);

const AddAnotherBed = () => {
    const { isPosting, handleSubmit } = useAddAnotherBed();

    return (
        <TextImageSplit
            heading="Want to add another bed?"
            containerClassName="summary-add-another-bed"
            src="/images/drawings/14.webp"
            nonDescriptionChildren={
                <AddBedButton isPosting={isPosting} handleSubmit={handleSubmit} />
            }
        >
            No problem, let’s take some details.
        </TextImageSplit>
    );
};

export default AddAnotherBed;
