import { HeadingProps } from './Heading';

const Heading3 = ({
    extraClasses = '',
    children,
    ...props
}: HeadingProps & React.ComponentProps<'h3'>) => {
    return (
        <h3 className={'heading-3 ' + extraClasses} {...props}>
            {children}
        </h3>
    );
};

export default Heading3;
