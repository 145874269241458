import Heading from '@components/headings/Heading';
import TopBanner from '@components/homepage/TopBanner';
import Description, { DescriptionProps } from 'lib/src/components/typography/Description';
import { WizardContentItem } from 'lib/src/types/shared/app/WizardContent';
import { FC, ReactNode } from 'react';

const StepHeader = ({
    title,
    children,
    headingComponent: HeadingComponent = Heading.Two,
    heading,
    containerClassName = '',
    ...descriptionProps
}: StepHeaderProps) => {
    return (
        <TopBanner>
            {heading && <HeadingComponent>{heading}</HeadingComponent>}
            <Description {...descriptionProps}>{children}</Description>
        </TopBanner>
    );
};

StepHeader.Wizard = ({ mainText, secondaryText }: WizardContentItem) => (
    <StepHeader heading={mainText}>{secondaryText}</StepHeader>
);

interface StepHeaderProps extends Omit<DescriptionProps, 'children'> {
    children?: ReactNode;
    headingComponent?: FC<{ children: ReactNode }>;
    heading: string;
    containerClassName?: string;
    nonDescriptionChildren?: ReactNode;
}

export default StepHeader;
