import { convertShapeToMetres, isAreaTooLarge } from 'lib/src/shared/helpers/shapes';
import { MEASUREMENT_UNIT_MAPPINGS } from 'lib/src/constants/enumTextMappings';
import { MEASUREMENT_SHAPE, MEASUREMENT_UNIT } from 'lib/src/constants/enums';
import { onChangeFunction } from 'lib/src/types/shared/FormInputProps';
import { MeasurementShape, MeasurementUnit } from 'lib/src/types/shared/app/GardenStyle';
import { useMemo } from 'react';

const { RECTANGLE, CIRCULAR, IRREGULAR } = MEASUREMENT_SHAPE;

const isStringNumber = (str: string | null) => str === '' || !Number.isNaN(+(str ?? ''));

const generateErrorMessage = (value: string) => `Please enter a correct value for ${value}`;

const AREA_TOO_LARGE_MESSAGE = 'Area is too large';

const useMeasurementInputs = ({
    handleChange,
    shape,
    diameter,
    measurementUnit,
    length,
    width,
}: MeasurementInputProps) => {
    const handleChangeWithSanitize = (key: string, value: string) =>
        handleChange(key, value.replace(/[^0-9.-]+/g, ''));

    const measurementUnitStr =
        MEASUREMENT_UNIT_MAPPINGS[measurementUnit ?? MEASUREMENT_UNIT.METRES];

    const { isShape, isQuad, isCircular, isIrregular } = useMemo(
        () => ({
            isShape: shape && ([RECTANGLE, CIRCULAR, IRREGULAR] as number[]).includes(shape),
            isQuad: shape && ([RECTANGLE] as number[]).includes(shape),
            isCircular: shape === CIRCULAR,
            isIrregular: shape === IRREGULAR,
        }),
        [shape],
    );

    const isBadInput = useMemo(() => {
        if (isCircular) return !isStringNumber(diameter) ? generateErrorMessage('Diameter') : '';
        if (isQuad) {
            for (const { value, keyName } of [
                { value: length, keyName: 'Length' },
                { value: width, keyName: 'Width' },
            ]) {
                if (!isStringNumber(value)) {
                    return generateErrorMessage(keyName);
                }
            }
            return false;
        }
        return false;
    }, [diameter, isCircular, isQuad, length, width]);

    const isAreaTooLargeMessage = useMemo(() => {
        if (isCircular) {
            const unit = measurementUnit ?? MEASUREMENT_UNIT.METRES;
            const parsedShape = convertShapeToMetres({
                unit,
                type: CIRCULAR,
                diameter: +(diameter ?? 0),
            });
            return isAreaTooLarge.metres(parsedShape) ? AREA_TOO_LARGE_MESSAGE : null;
        }
        if (isQuad) {
            const unit = measurementUnit ?? MEASUREMENT_UNIT.METRES;
            const parsedShape = convertShapeToMetres({
                unit,
                type: shape === RECTANGLE ? RECTANGLE : IRREGULAR,
                length: +(length ?? 0),
                width: +(width ?? 0),
            });
            return isAreaTooLarge.metres(parsedShape) ? AREA_TOO_LARGE_MESSAGE : null;
        }
        return null;
    }, [diameter, isCircular, isQuad, length, measurementUnit, shape, width]);

    return {
        isBadInput,
        isShape,
        isQuad,
        isCircular,
        isIrregular,
        measurementUnitStr,
        isAreaTooLargeMessage,
        handleChangeWithSanitize,
    };
};

interface MeasurementInputProps {
    handleChange: onChangeFunction<any>;
    shape: MeasurementShape | null;
    diameter: string | null;
    measurementUnit: MeasurementUnit | null;
    length: string | null;
    width: string | null;
}

export default useMeasurementInputs;
