import { createPortal } from 'react-dom';

import { useState, useEffect } from 'react';
import Title from '../typography/Title';
import useDarkMode from '../../hooks/useDarkMode';

// size options are: large, medium, small
const Modal: React.FC<ModalProps> = ({
    children,
    size = 'small',
    style = {},
    title,
    extraClassname = '',
    onClose = () => {},
}) => {
    const [hidden, updateHidden] = useState(true);
    const [darkMode] = useDarkMode();

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onClose]);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        // initially hidding the modal so that we can ease
        // in the opacity.
        updateHidden(false);

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const className = hidden ? 'hidden' : '';
    const modalRoot = document.getElementById('modal-root');
    if (modalRoot === null) return null;
    return createPortal(
        <div
            className={`modal-overlay ${className}`}
            data-theme={darkMode ? 'dark' : 'light'}
            aria-modal="true"
        >
            <div
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                onClick={onClose}
            ></div>
            <div
                className={`modal-body custom-scroll ${size} ${className} ${extraClassname}`}
                style={style}
            >
                {!!title && <Title>{title}</Title>}
                {children}
            </div>
        </div>,
        modalRoot,
    );
};

interface ModalProps {
    children: React.ReactNode;
    size?: 'small' | 'medium' | 'large';
    title?: string;
    style?: React.CSSProperties;
    extraClassname?: string;
    onClose?: () => void;
}

export default Modal;
