import Heading from '@components/headings/Heading';
import TextArea from 'lib/src/components/form/TextArea';
import { FormInputProps } from 'lib/src/types/shared/FormInputProps';
import { FC, ReactNode } from 'react';

const QuestionnaireTextArea = ({
    name,
    value,
    onChange,
    headingComponent: HeadingComponent = Heading.Three,
    heading,
    extraClasses,
    placeholder,
    maxLength = 3000,
}: QuestionnaireTextAreaProps) => {
    return (
        <div className={`text-area-container ${extraClasses}`}>
            <HeadingComponent>{heading}</HeadingComponent>
            <TextArea
                maxLength={maxLength}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </div>
    );
};

type QuestionnaireTextAreaProps = FormInputProps<string> & {
    headingComponent?: FC<{ children: ReactNode }>;
    heading: string;
    extraClasses?: string;
    placeholder?: string;
    maxLength?: number;
};

export default QuestionnaireTextArea;
