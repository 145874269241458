import { Bed } from 'lib/src/types/shared/app/Bed';
import { penceToPounds } from 'lib/src/utils/currency';
import Heading5 from '@components/headings/Heading5';
import {
    BED_SHAPE_VALUES,
    BED_VALUES,
    MEASUREMENT_UNIT_MAPPINGS,
    SOIL_VALUES,
    SUNLIGHT_VALUES,
} from 'lib/src/constants/enumTextMappings';
import ImageUpload from '@components/questionnaire/inputs/ImageUpload';
import { BED_SHAPE } from 'lib/src/constants/enums';

const BedCard = ({
    bed: {
        id,
        bedType,
        style,
        sunlight,
        soilType,
        shape,
        length,
        width,
        diameter,
        total,
        measurementUnit,
    },
    bed,
    bedNumber,
    addBedImages,
}: BedCardProps) => {
    return (
        <div key={id} className="order-card">
            <Heading5>
                <strong>
                    Bed {bedNumber} - £{penceToPounds(total)}
                </strong>
            </Heading5>

            <br />

            <div className="flex-row">
                <div className="flex-column flex-6">
                    <p>
                        <strong>Bed type: </strong>
                        {bedType ? BED_VALUES[bedType] : 'N/A'}
                    </p>

                    <p>
                        <strong>Style: </strong>
                        {style?.name}
                    </p>

                    <p>
                        <strong>Sunlight: </strong>
                        {sunlight ? SUNLIGHT_VALUES[sunlight] : 'N/A'}
                    </p>

                    <p>
                        <strong>Soil Type: </strong>
                        {soilType ? SOIL_VALUES[soilType] : 'N/A'}
                    </p>
                </div>

                <div className="flex-column flex-6">
                    <p>
                        <strong>Shape: </strong>
                        {shape ? BED_SHAPE_VALUES[shape] : 'N/A'}
                    </p>

                    {shape === BED_SHAPE.CIRCLE && (
                        <p>
                            <strong>Diameter: </strong>
                            {diameter} {MEASUREMENT_UNIT_MAPPINGS[measurementUnit ?? 1]}
                        </p>
                    )}
                    {(shape === BED_SHAPE.IRREGULAR || shape === BED_SHAPE.RECTANGLE) && (
                        <>
                            <p>
                                <strong>Length: </strong>
                                {length} {MEASUREMENT_UNIT_MAPPINGS[measurementUnit ?? 1]}
                            </p>

                            <p>
                                <strong>Width: </strong>
                                {width} {MEASUREMENT_UNIT_MAPPINGS[measurementUnit ?? 1]}
                            </p>
                        </>
                    )}
                </div>
            </div>

            <div className="divider dark"></div>
            <div className="flex-column flex-12">
                <ImageUpload.Multi
                    handleChange={(_, value) => addBedImages(bed.id, value || [])}
                    s3KeyName="imageS3Keys"
                    choose="Browse"
                    extraClasses="width-12"
                    s3Keys={bed.images.map(x => x.s3Key)}
                />
            </div>
        </div>
    );
};

interface BedCardProps {
    bed: Bed;
    bedNumber: number;
    addBedImages: (bedID: number, s3Keys: string[]) => void;
}

export default BedCard;
