import { ComponentProps } from 'react';

const Description: React.FC<DescriptionProps> = ({
    children,
    alignment = '',
    className = '',
    ...props
}) => {
    return (
        <p className={`page-description ${alignment} ${className}`} {...props}>
            {children}
        </p>
    );
};

export type DescriptionProps = ComponentProps<'p'> & {
    children: React.ReactNode;
    tag?: string;
    alignment?: 'left' | 'center' | 'right';
    className?: string;
};

export default Description;
