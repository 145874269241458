import axios, { AxiosResponse } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { API_URL } from '../config';
import { jwtAtom, refreshTokenAtom } from '../hooks/useIsLoggedIn';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useLogout } from './jwt';

export const useApi = () => {
    const [jwt, setJwt] = useAtom(jwtAtom);
    const [refreshToken, setRefreshToken] = useAtom(refreshTokenAtom);
    const logout = useLogout();

    const getConfig = useCallback(async () => {
        if (!jwt) return { headers: {} };
        let tmpJwt = jwt;
        // refresh token if required
        if (jwt && refreshToken) {
            const now = new Date().getTime() / 1000;
            const { exp = 0 } = jwtDecode<JwtPayload>(jwt);
            const minutesUntilExpiration = (exp - now) / 60;
            if (minutesUntilExpiration <= 1) {
                try {
                    const { data } = await axios.post<
                        RefreshTokenRequest,
                        AxiosResponse<LoginResponse>
                    >(`${API_URL}/auth/refresh-token`, { refreshToken, expiredToken: jwt });
                    tmpJwt = data.token;
                    setJwt(data.token);
                    setRefreshToken(data.refreshToken);
                } catch (e: any) {
                    if (e?.status === 401) {
                        logout();
                    }
                }
            }
        }
        return {
            headers: {
                Authorization: `Bearer ${tmpJwt}`,
            },
        };
    }, [jwt, logout, refreshToken, setJwt, setRefreshToken]);

    return useMemo(
        () => ({
            get: async <TResponseBody>(url: string): Promise<TResponseBody> =>
                axios
                    .get<TResponseBody>(`${API_URL}/${url}`, await getConfig())
                    .then(({ data }) => data),

            post: async <TRequestBody, TResponseBody>(url: string, data: TRequestBody) =>
                axios
                    .post<TResponseBody>(`${API_URL}/${url}`, data, await getConfig())
                    .then(({ data }) => data),

            put: async <TRequestBody, TResponseBody>(url: string, data: TRequestBody) =>
                axios
                    .put<TResponseBody>(`${API_URL}/${url}`, data, await getConfig())
                    .then(({ data }) => data),

            patch: async <TRequestBody, TResponseBody>(url: string, data: TRequestBody) =>
                axios
                    .patch<TResponseBody>(`${API_URL}/${url}`, data, await getConfig())
                    .then(({ data }) => data),

            delete: async <TResponseBody>(url: string) =>
                axios
                    .delete<TResponseBody>(`${API_URL}/${url}`, await getConfig())
                    .then(({ data }) => data),

            logError: async (body: ErrorLogRequest) => {
                return axios.post(`${API_URL}/error`, body, await getConfig());
            },
        }),
        [getConfig],
    );
};

interface RefreshTokenRequest {
    refreshToken: string;
    expiredToken: string;
}

export interface LoginResponse {
    token: string;
    refreshToken: string;
}

export interface ErrorLogRequest {
    message?: string;
    stackTrace?: string;
    appVersion: string;
    device: string;
    deviceOS: string;
    deviceRAM: number | null;
}
