import { useCallback, useMemo, useState } from 'react';
import useForm from 'lib/src/hooks/useForm';
import { useMutation } from 'react-query';
import { useSelectedOrderGuid } from '@utils/atoms';

import RegisterForm from './RegisterForm';
import { LoginResponse, useApi } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';
import { useAtom } from 'jotai';
import { jwtAtom, refreshTokenAtom } from 'lib/src/hooks/useIsLoggedIn';

export enum RegisterSteps {
    AccountDetails,
    AddressDetails,
}

interface Props {
    onSuccess: () => void;
}

const RegisterFormContainer: React.FC<Props> = ({ onSuccess }): JSX.Element => {
    const api = useApi();
    const [, setJwt] = useAtom(jwtAtom);
    const [, setRefreshToken] = useAtom(refreshTokenAtom);

    const [formState, handleChange] = useForm({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phoneNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        county: '',
        postcode: '',
        country: '',
    });
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [registerStep, setRegisterStep] = useState<RegisterSteps>(RegisterSteps.AccountDetails);

    const isNextDisabled = useMemo(() => {
        if (registerStep === RegisterSteps.AccountDetails) {
            return !(
                formState.firstName &&
                formState.lastName &&
                formState.email &&
                formState.password &&
                confirmPassword &&
                formState.password === confirmPassword &&
                formState.phoneNumber
            );
        }
        return !(
            formState.addressLine1 &&
            formState.city &&
            formState.county &&
            formState.postcode &&
            formState.country
        );
    }, [
        registerStep,
        formState.firstName,
        formState.lastName,
        formState.email,
        formState.password,
        confirmPassword,
        formState.phoneNumber,
        formState.addressLine1,
        formState.city,
        formState.county,
        formState.postcode,
        formState.country,
    ]);

    const handleButtonPress = () => {
        if (registerStep === RegisterSteps.AccountDetails) {
            setRegisterStep(RegisterSteps.AddressDetails);
        } else {
            handleSubmit();
        }
    };

    const validateConfirmPassword = useCallback(
        (confirmPassword: string) => {
            if (formState.password !== confirmPassword) {
                return 'Passwords do not match.';
            }
        },
        [formState.password],
    );
    const [selectedOrderGuid] = useSelectedOrderGuid();

    const postRegister = async (postBody: RegisterRequestModel) => {
        const data = await api.post<RegisterPostBody, RegisterResponse>('auth/register', {
            ...postBody,
            orderGuid: selectedOrderGuid,
        });

        setJwt(data.token);
        setRefreshToken(data.refreshToken);
        return data;
    };

    const {
        mutate: handleSubmit,
        isLoading,
        error,
    } = useMutation<LoginResponse, APIError>(() => postRegister({ ...formState }), {
        onSuccess: onSuccess,
    });

    return (
        <RegisterForm
            handleChange={handleChange}
            formState={formState}
            handleSubmit={handleSubmit}
            isPosting={isLoading}
            apiError={error}
            registerStep={registerStep}
            setRegisterStep={setRegisterStep}
            handleButtonPress={handleButtonPress}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            validateConfirmPassword={validateConfirmPassword}
            isNextDisabled={isNextDisabled}
        />
    );
};

export default RegisterFormContainer;

export interface RegisterRequestModel {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phoneNumber: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
    country: string;
}

interface RegisterPostBody extends RegisterRequestModel {
    orderGuid: string | null;
}
export interface RegisterResponse {
    token: string;
    refreshToken: string;
}
