const ActionButton: React.FC<ActionButtonProps> = ({
    children,
    type = 'submit',
    source = 'primary',
    icon,
    rightIcon,
    isPosting = false,
    success = false,
    disabled = false,
    onClick = () => {},
    ariaLabel,
}) => (
    <button
        className={`button ${source}`}
        type={type}
        disabled={isPosting || disabled}
        onClick={onClick}
        aria-label={ariaLabel}
    >
        {icon && !isPosting && <i className={`icon far fa-fw fa-${icon}`}></i>}
        {isPosting && <i className="icon far fa-fw fa-spinner fa-spin"></i>}
        {!isPosting && success && <i className="icon far fa-fw fa-check"></i>}
        <span className="text">{children}</span>
        {rightIcon && !isPosting && <i className={`icon right far fa-fw fa-${rightIcon}`}></i>}
    </button>
);

interface ActionButtonProps {
    children: React.ReactNode;
    type?: 'submit' | 'button' | 'reset';
    source?:
        | 'primary'
        | 'secondary'
        | 'positive'
        | 'negative'
        | 'negative-dark-green'
        | 'transparent';
    icon?: string;
    rightIcon?: string;
    isPosting?: boolean;
    success?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    ariaLabel?: string;
}

export default ActionButton;
