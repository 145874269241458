import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const darkModeStorage = localStorage.getItem('darkMode');
const darkModeAtom = atomWithStorage<null | boolean>(
    'darkMode',
    darkModeStorage ? JSON.parse(darkModeStorage) : null,
);

const useDarkMode = () => {
    const [darkMode, setDarkMode] = useAtom(darkModeAtom);
    const systemPrefersDark = false; // window.matchMedia('(prefers-color-scheme: dark)').matches;

    useEffect(() => {
        if (darkMode === null) {
            setDarkMode(systemPrefersDark);
            localStorage.setItem('darkMode', systemPrefersDark + '');
        }
    }, [systemPrefersDark, darkMode, setDarkMode]);

    return [darkMode, setDarkMode] as const;
};

export default useDarkMode;
