import { useCallback, useContext } from 'react';
import useForm from 'lib/src/hooks/useForm';
import { ObjectValues } from 'lib/src/types/helpers';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useApi } from 'lib/src/utils/api';
import { Order } from 'lib/src/types/shared/app/Order';
import { WIZARD_STAGE } from 'lib/src/constants/enums';
import { BedType } from 'lib/src/types/shared/app/GardenStyle';

export const PLANT_SELECTION = {
    FULL: 1,
    PLANTS: 2,
    WEEDS: 3,
    FEW_WEEDS: 4,
} as const;

export type PlantSelectionType = ObjectValues<typeof PLANT_SELECTION>;

type PostReadyRequest = {
    bedType: BedType | null;
};

const useReady = () => {
    const api = useApi();
    const {
        postStep,
        error,
        isPosting,
        order,
        bed,
        wizardContent: { content, ...otherWizardContentProps },
    } = useContext(QuestionnaireContext);

    const [form, handleChange] = useForm<PostReadyRequest>({
        bedType: bed?.bedType ?? null,
    });

    const bedSelection = useCallback(
        (value: BedType) => {
            handleChange('bedType', form.bedType === value ? null : value);
        },
        [form.bedType, handleChange],
    );

    const disableNext = !form.bedType;

    const handleSubmit = () => {
        if (disableNext || isPosting) return;
        const postReady = () =>
            api.post<PostReadyRequest, Order>(`orders/${order?.guid}/beds/${bed?.id}/stage/ready`, {
                bedType: form.bedType,
            });
        postStep(postReady);
    };

    return {
        form,
        bedSelection,
        handleSubmit,
        isPosting,
        error,
        content: { content: content[WIZARD_STAGE.READY], ...otherWizardContentProps },
        disableNext,
    };
};

export default useReady;
