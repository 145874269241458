import { ReactElement } from 'react';

const InfoBanner = ({ text }: Props) => {
    return (
        <div className="info-banner flex-row justify-center">
            <p>{text}</p>
        </div>
    );
};

interface Props {
    text: string | ReactElement;
}

export default InfoBanner;
