import Form from 'lib/src/components/form/Form';
import ProgressButton from '@components/questionnaire/buttons/ProgressButton';
import useYourBed from './hooks/useYourBed';
import { WizardContent } from '@components/questionnaire/WizardContentType';
import ImageUpload from '@components/questionnaire/inputs/ImageUpload';
import Checkboxes from '@components/questionnaire/inputs/Checkboxes';
import Loading from '@components/loading/Loading';
import FlowerBackground from '@components/misc/FlowerBackground';
import PageMeta from 'lib/src/components/pageMeta/PageMeta';
import SaveAndContinue from '@pages/questionnaire/SaveAndContinue';

const YourBed = () => {
    const {
        form: { imageS3Keys, footnoteIDs },
        handleSubmit,
        handleChange,
        options,
        content,
        isFetching,
        isPosting,
        error,
    } = useYourBed();

    return (
        <>
            <PageMeta title="Beds" />
            <section className="your-bed container flex-column gap-3">
                <WizardContent.Before content={content} isLoading={isFetching} error={error} />
                <Form onSubmit={handleSubmit} omitButtons>
                    <FlowerBackground />
                    <div className="flex-column gap-3">
                        {!isPosting ? (
                            <>
                                <Checkboxes
                                    values={footnoteIDs}
                                    options={options}
                                    name="footnoteIDs"
                                    onChange={handleChange}
                                    title="Please select all that apply to this bed"
                                    className="justify-center align-center"
                                />
                                <WizardContent.Between
                                    content={content}
                                    isLoading={isFetching}
                                    error={error}
                                />
                                <ImageUpload.Multi
                                    s3KeyName="imageS3Keys"
                                    s3Keys={imageS3Keys}
                                    handleChange={handleChange}
                                    extraClasses="width-12"
                                />
                            </>
                        ) : (
                            <Loading size="large" />
                        )}

                        <WizardContent.After
                            content={content}
                            isLoading={isFetching}
                            error={error}
                        />

                        <ProgressButton />
                        <SaveAndContinue />
                    </div>
                </Form>
            </section>
        </>
    );
};

export default YourBed;
