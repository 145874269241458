import React from 'react';
import { Switch } from 'react-router-dom';

import SubRouter from './SubRouter';
import DefaultRedirect from './DefaultRedirect';
import Home from '@pages/home/Home';
import QuestionnaireRoutes from './QuestionnaireRoutes';
import AccountRoutes from './AccountRoutes';
import QuestionnaireProvider from '@pages/questionnaire/QuestionnaireProvider';

const Routes: React.FC = () => (
    <Switch>
        <SubRouter exact path="/">
            <QuestionnaireProvider>
                <Home />
            </QuestionnaireProvider>
        </SubRouter>
        <SubRouter path="/account">
            <AccountRoutes />
        </SubRouter>
        <SubRouter path="/questionnaire">
            <QuestionnaireRoutes />
        </SubRouter>
        <DefaultRedirect to="/" />
    </Switch>
);

export default Routes;
