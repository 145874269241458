import { useContext } from 'react';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';

const EstimatedDelivery = () => {
    const { order } = useContext(QuestionnaireContext);

    const deliveryDate = order?.expectedDeliveryDate;

    if (!deliveryDate) return null;

    return (
        <div className="estimated-delivery">
            <i className="far fa-truck"></i>
            <span>Estimated delivery: {deliveryDate}</span>
        </div>
    );
};

export default EstimatedDelivery;
