import Modal from 'lib/src/components/modal/Modal';
import Logo from '/images/hc-logo.svg';
import Paragraph from '@components/paragraph/Paragraph';
import LoginFormContainer from '@pages/auth/login/LoginFormContainer';
import { useState } from 'react';
import Description from 'lib/src/components/typography/Description';
import Heading2 from '@components/headings/Heading2';
import RegisterFormContainer from '@pages/auth/register/RegisterFormContainer';

interface Props {
    closeModal: () => void;
}

const AuthModal: React.FC<Props> = ({ closeModal }) => {
    const [showLogin, setShowLogin] = useState(true);

    return (
        <Modal extraClassname="auth-modal" onClose={closeModal}>
            <div className="flex-row">
                <div className="flex-column align-center flower-left-background branding-container">
                    <img src={Logo} alt="Holden Clough Nurseries" />

                    <div className="divider"></div>

                    <Paragraph>For the wonder of plants</Paragraph>
                </div>
                <div className="flex-column align-center form-area">
                    <div className="form-wrapper">
                        {showLogin ? (
                            <>
                                <Heading2>Welcome back!</Heading2>
                                <LoginFormContainer onSuccess={closeModal} />
                                <div className="divider"></div>
                                <Description>
                                    Don't have an account?{' '}
                                    <button onClick={() => setShowLogin(false)}>Sign Up</button>
                                </Description>
                            </>
                        ) : (
                            <>
                                <Heading2>Create an account</Heading2>
                                <RegisterFormContainer onSuccess={closeModal} />
                                <div className="divider"></div>
                                <Description>
                                    Don't have an account?{' '}
                                    <button onClick={() => setShowLogin(true)}>Log in</button>
                                </Description>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AuthModal;
