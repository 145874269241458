import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DefaultRedirect from './DefaultRedirect';
import Questionnaire from '@pages/questionnaire/Questionnaire';
import QuestionnaireProvider from '@pages/questionnaire/QuestionnaireProvider';
import Failure from '@pages/questionnaire/Failure';
import Success from '@pages/questionnaire/Success';

const QuestionnaireRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}/`}>
                <QuestionnaireProvider>
                    <Questionnaire />
                </QuestionnaireProvider>
            </Route>
            <Route exact path={`${path}/success`}>
                <Success />
            </Route>
            <Route exact path={`${path}/error`}>
                <Failure />
            </Route>
            <DefaultRedirect to="/" />
        </Switch>
    );
};

export default QuestionnaireRoutes;
