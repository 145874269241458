import { atomWithStorage } from 'jotai/utils';
import { useAtom, useSetAtom } from 'jotai';
import { WizardStage } from 'lib/src/types/shared/app/WizardContent';
import { getTokenFromStorage } from 'lib/src/utils/generic';

export const selectedOrderGuidAtomKey = 'selectedOrderGuid';
export const selectedOrderGuidAtom = atomWithStorage<string | null>(selectedOrderGuidAtomKey, null);

export const useSelectedOrderGuid = () => {
    // useAtom/useAtomValue are providing an uninitialised value on mount so circumventing that here.
    const selectedOrderGuid = JSON.parse(localStorage.getItem(selectedOrderGuidAtomKey) || 'null');
    const setSelectedOrderGuid = useSetAtom(selectedOrderGuidAtom);
    return [selectedOrderGuid, setSelectedOrderGuid];
};

export const selectedBedIDAtomKey = 'selectedBedID';
export const selectedBedIDAtom = atomWithStorage<number | null>(selectedBedIDAtomKey, null);
export const useSelectedBedID = () => {
    return useAtom(selectedBedIDAtom);
};

export const stepAtom = atomWithStorage<WizardStage | null>(
    'stepAtom',
    getTokenFromStorage('stepAtom'),
);

export const useSelectedStep = () => {
    return useAtom(stepAtom);
};
