import { MEASUREMENT_UNIT_MAPPINGS } from '../../constants/enumTextMappings';
import { BED_SHAPE } from '../../constants/enums';
import { Shape } from './shapes';

const getBedDimensions = (props: Partial<Shape>) => {
    const measurementUnitStr = MEASUREMENT_UNIT_MAPPINGS[props.unit ?? 1];

    switch (props.type) {
        case BED_SHAPE.CIRCLE:
            return `(${props.diameter}${measurementUnitStr} Diameter)`;
        case BED_SHAPE.RECTANGLE:
        case BED_SHAPE.IRREGULAR:
            return `(${props.length}${measurementUnitStr} Length x ${props.width}${measurementUnitStr} Width)`;
        default:
            return '';
    }
};

export const getBedName = (props: Partial<Shape>, name?: string | null) =>
    `Your ${name ?? 'Incomplete bed'} ${getBedDimensions(props)}`;
