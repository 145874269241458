import Heading from '@components/headings/Heading';
import Description from 'lib/src/components/typography/Description';
import MultiFileUpload, {
    FileUploadButtonsProps,
} from '../../../../../lib/src/components/form/MultiFileUpload';
import { onChangeFunction } from 'lib/src/types/shared/FormInputProps';

const ImageUpload = {
    Multi: ({
        handleChange,
        s3Keys,
        s3KeyName,
        choose = (
            <>
                <i className="image-icon far fa-download"></i> Upload images
            </>
        ),
        add,
        clear,
        extraClasses = '',
    }: ImageUploadMultiProps) => (
        <div className={`flex-column image-upload ${extraClasses}`}>
            <Heading.Three>Upload files</Heading.Three>
            <Description>Browse to choose your file(s)</Description>
            <MultiFileUpload
                buttons={{
                    choose,
                    add,
                    clear,
                }}
                position="center"
                maxNumberOfFiles={10}
                name={s3KeyName}
                values={s3Keys}
                onChange={handleChange}
            />
        </div>
    ),
};

type ImageUploadMultiProps = FileUploadButtonsProps & {
    handleChange: onChangeFunction<string[] | null>;
    s3Keys: string[];
    s3KeyName: string;
    extraClasses?: string;
};

export default ImageUpload;
