import { HeadingProps } from './Heading';

const Heading2 = ({
    extraClasses = '',
    children,
    ...props
}: HeadingProps & React.ComponentProps<'h2'>) => {
    return (
        <h2 className={'heading-2 ' + extraClasses} {...props}>
            {children}
        </h2>
    );
};

export default Heading2;
