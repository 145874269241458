import { useContext, useState } from 'react';

import AccordionWrapper from '@components/accordions/AccordionWrapper';
import SummaryAccordionItem from './SummaryAccordionItem';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import SummaryAccordionOptionalExtras from './SummaryAccordionOptionalExtras';

const SummaryAccordion = () => {
    const [isOpen, setIsOpen] = useState<string | undefined>(undefined);

    const { order } = useContext(QuestionnaireContext);

    return (
        <AccordionWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
            {order?.beds.map((item, index) => (
                <SummaryAccordionItem
                    key={index}
                    bedNumber={index + 1}
                    products={order.products}
                    item={item}
                    isOpen={isOpen}
                />
            ))}

            {(order?.products?.length ?? 0) > 0 && (
                <>
                    <hr />
                    <SummaryAccordionOptionalExtras order={order} />
                </>
            )}
        </AccordionWrapper>
    );
};

export default SummaryAccordion;
