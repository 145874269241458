import React from 'react';

import useFieldValidation from '../../hooks/useFieldValidation';
import { FormInputProps } from '../../types/shared/FormInputProps';
import FormField from './FormField';
import Phone from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';

const PhoneInput: React.FC<PhoneInputProps> = ({
    name,
    value,
    onChange,
    label = '',
    placeholder = '',
    required = false,
    disabled,
    customValidate,
    ariaLabel = 'Enter text',
}) => {
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
    });

    return (
        <FormField name={name} label={label} required={required} error={error}>
            <Phone
                className={`form-input text-area ${error ? 'error' : ''}`}
                placeholder={placeholder}
                specialLabel=""
                name={name}
                id={name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                aria-label={ariaLabel}
                aria-required={required ? 'true' : 'false'}
                country="GB"
                smartCaret={true}
            />
        </FormField>
    );

    function handleBlur() {
        showError();
    }
    function handleChange(value: string) {
        onChange(name, value);
    }
};

interface PhoneInputProps extends FormInputProps<string> {
    placeholder?: string;
    label?: string;
    validationRegExp?: string;
    minLength?: number;
    maxLength?: number;
}

export default PhoneInput;
