import { Key } from 'react';
import { DropdownOption } from '../types/shared/DropdownOption';

export function isEmpty(item: any): boolean {
    if (Array.isArray(item)) return !item.length;
    if (typeof item === 'string') return !item.trim().length;
    if (item instanceof Date) return isNaN(item.valueOf());
    if (typeof item === 'object') return isObjEmpty(item);
    if (typeof item === 'number') return false;

    return !item;
}

function isObjEmpty(obj: Record<string, unknown>): boolean {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function convertArrToObj<T>(arr: T[], field = 'id'): Record<string, T> {
    return arr.reduce<Record<string, T>>((acc, item) => {
        // todo
        //@ts-ignore
        acc[item[field]] = item;
        return acc;
    }, {});
}

export const formatUnderscoreToTitleCase = (string: string): string => {
    const sentence = string.toLowerCase().split('_');
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(' ');
};

export const formatUnderscoreToSentenceCase = (string: string): string => {
    const sentence = string.toLowerCase().split('_');
    sentence[0] = sentence[0][0].toUpperCase() + sentence[0].slice(1);

    return sentence.join(' ');
};

export const mapStringedKeys = (options: number[], map: Record<Key, string>): string =>
    !options.length ? 'N/A' : options.map(x => map[x]).join(', ');

export const reverseMapper = <T extends Key>(
    target: object,
    casing: 'title' | 'sentence' = 'title',
) =>
    Object.keys(target).reduce(
        (acc, item) => ({
            ...acc,
            [target[item as keyof typeof target]]:
                casing === 'title'
                    ? formatUnderscoreToTitleCase(item)
                    : formatUnderscoreToSentenceCase(item),
        }),
        {},
    ) as Record<T, string>;

export const reverseMapperDropdown = <T extends Key>(target: object) =>
    Object.keys(target).map(item => ({
        label: formatUnderscoreToTitleCase(item),
        value: target[item as keyof typeof target],
    })) as DropdownOption<T>[];

export const areArraysEqual = <T>(a: T[], b: T[]): boolean => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};

export const tryParseJson = (str: string | null | undefined) => {
    if (!str) return '';
    try {
        return JSON.parse(str);
    } catch {
        return '';
    }
};

export const getTokenFromStorage = (key: string) => {
    const encoded = localStorage.getItem(key);
    if (!encoded) return null;
    return JSON.parse(encoded);
};
