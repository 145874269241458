import * as Accordion from '@radix-ui/react-accordion';

const AccordionWrapper = ({ isOpen, setIsOpen, children }: AccordionWrapperProps) => {
    function onValueChange(val: string) {
        if (!isOpen) {
            setIsOpen(val);
        } else {
            setIsOpen(undefined);
        }
    }

    return (
        <Accordion.Root
            className="accordion"
            onValueChange={(val: string) => onValueChange(val)}
            type="single"
            collapsible
        >
            {children}
        </Accordion.Root>
    );
};

interface AccordionWrapperProps {
    isOpen: string | undefined;
    setIsOpen: (val: string | undefined) => void;
    children: React.ReactNode;
}
export default AccordionWrapper;
