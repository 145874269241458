import useForm from 'lib/src/hooks/useForm';
import { Soil } from 'lib/src/types/shared/app/GardenStyle';
import { useApi } from 'lib/src/utils/api';
import { Order } from 'lib/src/types/shared/app/Order';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useContext, useMemo } from 'react';
import { SOIL_TYPES, WIZARD_STAGE } from 'lib/src/constants/enums';
import { SOIL_VALUES } from 'lib/src/constants/enumTextMappings';
import { withKeys } from 'lib/src/shared/enums/dropdownEnums';
import { onChangeFunction } from 'lib/src/types/shared/FormInputProps';
import { PreparationStep } from '../../Ready/hooks/useBedContent';

const { CLAY, SOIL, SANDY } = SOIL_TYPES;

interface PostSoilRequest {
    soilType: Soil | null;
    isCoastal: boolean | null;
}

export type ExtraQuestion<T> = {
    text: string;
    onChange: onChangeFunction<T>;
    value: T;
    type: 'checkbox' | '';
};

export type SoilObject = {
    heading: string;
    description: string;
    type: Soil;
    id: number;
    src: string;
    alt: string;
    extraQuestion?: ExtraQuestion<any>;
    preparationSteps?: PreparationStep[];
};

const useSoil = () => {
    const {
        isPosting,
        order,
        bed,
        postStep,
        wizardContent: { content, isFetching, error },
    } = useContext(QuestionnaireContext);

    const api = useApi();
    const [form, handleChange] = useForm<PostSoilRequest>({
        soilType: bed?.soilType ?? null,
        isCoastal: bed?.isCoastal ?? null,
    });

    const disableNext = !form.soilType;
    const handleSubmit = () => {
        if (disableNext || isPosting) return;
        const postBody = { ...form };
        if (postBody.soilType !== SANDY) postBody.isCoastal = false;
        const postReady = () =>
            api.post<PostSoilRequest, Order>(
                `orders/${order?.guid}/beds/${bed?.id}/stage/soil`,
                postBody,
            );
        postStep(postReady);
    };

    const changeSoil = (value: Soil) =>
        handleChange('soilType', value === form.soilType ? null : value);

    const soilButtons: Readonly<SoilObject[]> = useMemo<SoilObject[]>(
        () =>
            withKeys<Omit<SoilObject, 'id'>, 'id'>(
                [
                    {
                        src: '/images/drawings/12.webp',
                        alt: 'bag',
                        heading: SOIL_VALUES[CLAY],
                        description: 'A sticky and dense texture, that can be heavy to dig.',
                        type: CLAY,
                        preparationSteps: withKeys(
                            [
                                {
                                    text: `${SOIL_VALUES[CLAY]} - Lorem Ipsum dolor sic amet`,
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Lorem Ipsum dolor sic amet',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Lorem Ipsum dolor sic amet',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Lorem Ipsum dolor sic amet',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                            ],
                            'id',
                        ),
                    },
                    {
                        src: '/images/drawings/12.webp',
                        alt: 'bag',
                        heading: SOIL_VALUES[SOIL],
                        description: 'A good mix that loosely falls apart when dug over.',
                        type: SOIL,
                        preparationSteps: withKeys(
                            [
                                {
                                    text: `${SOIL_VALUES[SOIL]} - Lorem Ipsum dolor sic amet`,
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Lorem Ipsum dolor sic amet',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Lorem Ipsum dolor sic amet',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Lorem Ipsum dolor sic amet',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                            ],
                            'id',
                        ),
                    },
                    {
                        src: '/images/drawings/12.webp',
                        alt: 'bag',
                        heading: SOIL_VALUES[SANDY],
                        description: 'A drier soil, with a dusty or stony texture.',
                        type: SANDY,
                        preparationSteps: withKeys(
                            [
                                {
                                    text: `${SOIL_VALUES[SANDY]} - Lorem Ipsum dolor sic amet`,
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Lorem Ipsum dolor sic amet',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Lorem Ipsum dolor sic amet',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Lorem Ipsum dolor sic amet',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                            ],
                            'id',
                        ),
                        extraQuestion: {
                            text: 'Is your garden coastal?',
                            onChange: (_, val) => handleChange('isCoastal', val),
                            value: form.isCoastal,
                            type: 'checkbox',
                        },
                    },
                ],
                'id',
                1,
            ),
        [form.isCoastal, handleChange],
    );

    const selectedButton = useMemo(
        () => soilButtons.find(x => x.type === form.soilType),
        [form.soilType, soilButtons],
    );

    return {
        form,
        changeSoil,
        handleSubmit,
        content: content[WIZARD_STAGE.SOIL],
        isFetching,
        error,
        disableNext,
        selectedButton,
        soilButtons,
    };
};

export default useSoil;
