import useAnythingElse from './hooks/useAnythingElse';
import Form from 'lib/src/components/form/Form';
import ProgressButton from '@components/questionnaire/buttons/ProgressButton';
import { WizardContent } from '@components/questionnaire/WizardContentType';
import QuestionnaireTextArea from '@components/questionnaire/inputs/QuestionnaireTextArea';
import FlowerBackground from '@components/misc/FlowerBackground';
import PageMeta from 'lib/src/components/pageMeta/PageMeta';
import SaveAndContinue from '@pages/questionnaire/SaveAndContinue';

const AnythingElse = () => {
    const {
        form: { notes },
        textMaxLength,
        disableNext,
        handleSubmit,
        handleChange,
        isPosting,
        error,
        content: { content, isFetching: wizardContentIsFetching, error: wizardContentError },
    } = useAnythingElse();

    return (
        <>
            <PageMeta title="Anything Else" />

            <section className="anything-else container flex-column gap-3">
                <WizardContent.Before
                    content={content}
                    error={wizardContentError}
                    isLoading={wizardContentIsFetching}
                />
                <Form onSubmit={handleSubmit} apiError={error} isPosting={isPosting} omitButtons>
                    <FlowerBackground />
                    <div className="flex-column gap-3">
                        <div className="split-container width-12 flex-row justify-center gap-3">
                            <QuestionnaireTextArea
                                name="notes"
                                value={notes}
                                onChange={handleChange}
                                heading="No detail is too silly, so let us know below."
                                placeholder="Type here..."
                                extraClasses="width-12"
                                maxLength={textMaxLength}
                            />
                        </div>
                        <WizardContent.Between
                            content={content}
                            error={wizardContentError}
                            isLoading={wizardContentIsFetching}
                        />
                        <WizardContent.After
                            content={content}
                            error={wizardContentError}
                            isLoading={wizardContentIsFetching}
                        />
                        <ProgressButton isNextDisabled={disableNext} />
                        <SaveAndContinue />
                    </div>
                </Form>
            </section>
        </>
    );
};

export default AnythingElse;
