import { useLogout } from 'lib/src/utils/jwt';
import { useSelectedBedID, useSelectedOrderGuid } from './atoms';

export const useWebLogout = () => {
    const logout = useLogout();
    const [, setSelectedGuid] = useSelectedOrderGuid();
    const [, setSelectedBedId] = useSelectedBedID();

    return (redirectPath = '/') => {
        setSelectedGuid(null);
        setSelectedBedId(null);
        logout(redirectPath);
    };
};
