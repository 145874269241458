import Form from 'lib/src/components/form/Form';
import ProgressButton from '@components/questionnaire/buttons/ProgressButton';
import useSoil from './hooks/useSoil';
import { WizardContent } from '@components/questionnaire/WizardContentType';
import FlowerBackground from '@components/misc/FlowerBackground';
import SaveAndContinue from '@pages/questionnaire/SaveAndContinue';
import PageMeta from 'lib/src/components/pageMeta/PageMeta';
import SoilButton from './SoilButton';

const Soil = () => {
    const {
        form: { soilType },
        handleSubmit,
        changeSoil,
        content,
        isFetching,
        error,
        disableNext,
        soilButtons,
    } = useSoil();

    return (
        <>
            <PageMeta title="Soil" />
            <section className="soil container flex-column gap-3">
                <WizardContent.Before content={content} isLoading={isFetching} error={error} />
                <Form onSubmit={handleSubmit} omitButtons>
                    <FlowerBackground />
                    <div className="flex-column gap-3">
                        <div className="flex-row gap-6 soil-button-row justify-center">
                            {soilButtons.map(({ id, type, ...props }) => (
                                <SoilButton
                                    key={id}
                                    title={`${id}.`}
                                    type={type}
                                    id={id}
                                    isActive={soilType === type}
                                    onClick={() => changeSoil(type)}
                                    {...props}
                                />
                            ))}
                        </div>
                        <WizardContent.Between
                            content={content}
                            isLoading={isFetching}
                            error={error}
                        />
                        <WizardContent.After
                            content={content}
                            isLoading={isFetching}
                            error={error}
                        />
                        <ProgressButton isNextDisabled={disableNext} />
                        <SaveAndContinue />
                    </div>
                </Form>
            </section>
        </>
    );
};

export default Soil;
