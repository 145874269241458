import React from 'react';
import { Helmet } from 'react-helmet';

const PageMeta: React.FC<PageMetaProps> = ({ title, isAdmin = false }) => {
    const websiteName = isAdmin ? 'Holden Clough Admin' : 'Holden Clough Web';

    return (
        <Helmet>
            <title>
                {title} | {websiteName}
            </title>
        </Helmet>
    );
};

interface PageMetaProps {
    title: string;
    isAdmin?: boolean;
}

export default PageMeta;
