import { withKeys } from 'lib/src/shared/enums/dropdownEnums';
import { FC, MouseEventHandler, useContext, useMemo } from 'react';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';

type Direction = 'left' | 'right' | 'up' | 'down';

const Arrow = ({ direction }: { direction: Direction }) => {
    return <i className={`far fa-arrow-${direction}`} />;
};

interface ProgressButtonActions {
    direction: Direction;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    type?: 'submit' | 'reset' | 'button' | undefined;
}

const ProgressButton: FC<ProgressButtonProps> = ({
    onNext,
    onPrev,
    isPrevDisabled = false,
    isNextDisabled = false,
    submitOnNext = true,
}) => {
    const { step, goToPreviousStep } = useContext(QuestionnaireContext);
    const arrows = useMemo(
        () =>
            withKeys<ProgressButtonActions>([
                { direction: 'left', onClick: onPrev ?? goToPreviousStep, type: 'button' },
                {
                    direction: 'right',
                    onClick: onNext,
                    type: submitOnNext ? 'submit' : 'button',
                },
            ]),
        [goToPreviousStep, onNext, onPrev, submitOnNext],
    );

    const getIsDisabled = (direction: Direction) => {
        if (direction === 'left') {
            return isPrevDisabled || (direction === 'left' && (!step || step <= 1))
                ? 'disabled'
                : '';
        }

        return isNextDisabled ? 'disabled' : '';
    };

    return (
        <div className="flex-row gap-2 progress-button justify-center">
            {arrows.map(({ direction, onClick, type, key }) => {
                const disabledClass = getIsDisabled(direction);
                return (
                    <button
                        className={`text-center arrow ${disabledClass}`}
                        onClick={onClick}
                        type={type}
                        key={key}
                        disabled={!!disabledClass}
                    >
                        <Arrow direction={direction} key={key} />
                    </button>
                );
            })}
        </div>
    );
};

interface ProgressButtonProps {
    onNext?: MouseEventHandler<HTMLButtonElement>;
    onPrev?: MouseEventHandler<HTMLButtonElement>;
    submitOnNext?: boolean;
    isPrevDisabled?: boolean;
    isNextDisabled?: boolean;
}

export default ProgressButton;
