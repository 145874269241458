import userLogo from '/images/icons/user.svg';
import shoppingBag from '/images/icons/shopping-bag.svg';
import { Link } from 'react-router-dom';
import { a, useSpring } from 'react-spring';
import { withKeys } from 'lib/src/shared/enums/dropdownEnums';
import { useContext, useMemo, useState } from 'react';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { WIZARD_STAGE } from 'lib/src/constants/enums';
import AuthModal from '@components/auth/AuthModal';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';

const LinkItem = ({ to, src, alt, onClick, disabled }: LinkItemProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const spring = useSpring({ scale: isHovered ? 1.2 : 1 });

    return (
        <a.div
            style={spring}
            onMouseEnter={() => setIsHovered(!disabled)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {to === null ? (
                <button
                    onClick={onClick}
                    disabled={disabled}
                    className={`${disabled ? 'cursor-not-allowed' : ''}`}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <img className="mixed-blend-none" src={src} alt={alt} />
                </button>
            ) : (
                <Link
                    to={to}
                    onClick={disabled ? () => {} : onClick}
                    className={`${disabled ? 'cursor-not-allowed' : ''}`}
                >
                    <img className="mixed-blend-none" src={src} alt={alt} />
                </Link>
            )}
        </a.div>
    );
};

type LinkItemProps = {
    to: string | null;
    src: string;
    alt: string;
    onClick?: () => void;
    disabled?: boolean;
};

const LinkItems = () => {
    const [showAuthModal, setShowAuthModal] = useState(false);
    const isLoggedIn = useIsLoggedIn();

    const { setStep, bed } = useContext(QuestionnaireContext);
    const bedStep = bed?.currentStage ?? 1;
    const linkData = useMemo(
        () =>
            withKeys<LinkItemProps>([
                {
                    to: isLoggedIn ? '/account' : null,
                    src: userLogo,
                    alt: 'User logo',
                    onClick: () => setShowAuthModal(true),
                },
                {
                    to: '/questionnaire',
                    src: shoppingBag,
                    alt: 'Shopping Bag',
                    onClick: () => setStep(WIZARD_STAGE.SUMMARY),
                    disabled: bedStep < WIZARD_STAGE.SUMMARY,
                },
            ]),
        [bedStep, setStep, isLoggedIn],
    );
    return (
        <>
            <div className="flex-row align-center gap-2">
                {linkData.map(x => (
                    <LinkItem {...x} />
                ))}
            </div>

            {showAuthModal && <AuthModal closeModal={() => setShowAuthModal(false)} />}
        </>
    );
};

export default LinkItems;
