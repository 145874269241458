import AuthModal from '@components/auth/AuthModal';
import Header from '@components/header/Header';
import { Heading } from '@components/headings/Heading';
import FlowerBackground from '@components/misc/FlowerBackground';
import Paragraph from '@components/paragraph/Paragraph';
import Steps from '@components/questionnaire/steps/Steps';
import LinkButton from 'lib/src/components/button/LinkButton';
import PageMeta from 'lib/src/components/pageMeta/PageMeta';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Home: React.FC = (): JSX.Element => {
    const location = useLocation<{ prevPath: string }>();
    const isLoggedIn = useIsLoggedIn();

    const [showAuthModal, setShowAuthModal] = useState(false);

    useEffect(() => {
        if (location?.state?.prevPath === '/account' && !isLoggedIn) {
            setShowAuthModal(true);
        }
    }, [isLoggedIn, location?.state?.prevPath]);

    return (
        <>
            <Header />
            <div id="home-page">
                <PageMeta title="Home" />

                <FlowerBackground />

                <div className="container flex-column align-center text-center">
                    <Heading.One>The Wonder Garden</Heading.One>

                    <Paragraph className="text-center" fontFamily="caslon" fontStyle="italic">
                        Are you ready to create the flower beds you have always dreamed of?
                        <br />
                        Answer a few simple questions and let’s work some magic in your garden.
                    </Paragraph>

                    <LinkButton href="/questionnaire">Start now</LinkButton>
                </div>

                <Steps />
            </div>

            {showAuthModal && <AuthModal closeModal={() => setShowAuthModal(false)} />}
        </>
    );
};

export default Home;
