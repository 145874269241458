import { useParams } from 'react-router-dom';
import { Heading1 } from '@components/headings/Heading';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import Description from 'lib/src/components/typography/Description';

const Failure = () => {
    const params = useParams<{ session_id: string; guid: string }>();

    const orderId = params.guid;

    return (
        <div id="questionnaire" className="height-full">
            <main className="container height-full flex-column align-center justify-between">
                <div className="text-center">
                    <Heading1>Something went wrong</Heading1>
                    <Description>
                        We were unable to process your order. <br /> <br />
                        Please try again and if the issue persists, get in touch with us at{' '}
                        <a
                            href={`mailto:info@holdenclough.com?subject=Payment issues with order: #${orderId}`}
                        >
                            info@holdenclough.com
                        </a>
                    </Description>
                </div>

                <ButtonRow alignment="left">
                    <LinkButton href="/questionnaire" source="positive">
                        Back to questionnaire
                    </LinkButton>
                </ButtonRow>
            </main>
        </div>
    );
};

export default Failure;
