import { WizardContentItem } from 'lib/src/types/shared/app/WizardContent';

const Text = ({ mainText }: WizardContentItem) => {
    return (
        <div className="wizard-text flex-row align-center justify-center">
            <p>{mainText}</p>
        </div>
    );
};

export default Text;
