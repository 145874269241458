import useForm from 'lib/src/hooks/useForm';
import { useApi } from 'lib/src/utils/api';
import { useContext, useMemo } from 'react';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { Order } from 'lib/src/types/shared/app/Order';
import { WIZARD_STAGE } from 'lib/src/constants/enums';
import { useQuery } from 'react-query';
import { CacheKeys } from '../../../../../types/query/CacheKeys';
import { GardenStyle } from 'lib/src/types/shared/app/GardenStyle';

interface PostStyleRequest {
    gardenStyleID: number | null;
}

export interface GardenListStyle extends GardenStyle {
    isDisabled: boolean;
}
const useStyle = () => {
    const { isPosting, order, bed, postStep } = useContext(QuestionnaireContext);
    const api = useApi();

    const [form, handleChange] = useForm<PostStyleRequest>({
        gardenStyleID: bed?.gardenStyleID ?? null,
    });

    const disableNext = !form.gardenStyleID;

    const handleSubmit = () => {
        if (disableNext || isPosting) return;
        const postReady = () =>
            api.post<PostStyleRequest, Order>(
                `orders/${order?.guid}/beds/${bed?.id}/stage/style`,
                form,
            );
        postStep(postReady);
    };

    const changeStyle = (id: number) =>
        handleChange('gardenStyleID', id === form.gardenStyleID ? null : id);

    const {
        wizardContent: { content, isFetching, error },
    } = useContext(QuestionnaireContext);

    const fetchGardenStyles = () => api.get<GardenStyle[]>('garden/styles');

    const {
        data: gardenStyles,
        isFetching: gardenStylesIsFetching,
        error: gardenStylesError,
    } = useQuery<GardenStyle[]>(CacheKeys.GardenStyles, fetchGardenStyles, {
        refetchOnWindowFocus: false,
    });

    const flaggedStyles: any[] = useMemo(
        () =>
            (
                gardenStyles?.map(style => {
                    const isSunlightMatch = style.sunlightOptions?.find(s => s === bed?.sunlight);
                    const isSoilTypeMatch = style.soilTypeOptions?.find(s => s === bed?.soilType);
                    const isCoastalMatch = bed?.isCoastal === style.isCoastal;
                    const isDisabled = !isSunlightMatch || !isSoilTypeMatch || !isCoastalMatch;
                    const newStyle: GardenListStyle = { ...style, isDisabled: false };
                    newStyle.isDisabled = isDisabled;
                    return newStyle;
                }) ?? []
            )
                .sort((x, y) => x.id - y.id)
                .sort(({ isDisabled }) => (isDisabled ? 1 : -1)),
        [bed?.isCoastal, bed?.soilType, bed?.sunlight, gardenStyles],
    );

    return {
        form,
        changeStyle,
        handleSubmit,
        content: content[WIZARD_STAGE.STYLE],
        gardenStyles: {
            styles: flaggedStyles,
            isFetching: gardenStylesIsFetching,
            error: gardenStylesError,
        },
        isFetching,
        error,
        disableNext,
    };
};

export default useStyle;
