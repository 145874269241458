import FooterSubSection, { FooterItem } from './FooterSubSection';
import WobblyBar from '@components/misc/WobblyBar';
import hcLogo from '/images/hc-logo.svg';

const holdenCloughItems: FooterItem[] = [
    { name: 'Main site', href: 'https://holdencloughnursery.com/' },
];

const contactItems: FooterItem[] = [
    { name: '01200 447615', href: 'tel:01200447615', faClass: 'far fa-phone' },
    {
        name: 'info@holdenclough.com',
        href: 'mailto:info@holdenclough.com',
        faClass: 'far fa-envelope',
    },
];

const addressInfo: FooterItem[] = [
    {
        name: 'Holden Clough Nurseries, Holden, Bolton-by-Bowland, Lancashire, BB7 4PF',
        href: 'https://goo.gl/maps/jQWVouUbWMLFxRSj9',
        faClass: 'fal fa-map-marker-alt',
    },
];

const Footer = () => {
    return (
        <div>
            <WobblyBar />
            <footer className="footer">
                <div className="footer-content-container container">
                    <div className="footer-content flex-row width-12 gap-3 justify-between">
                        <a href="/">
                            <img src={hcLogo} alt="Holden Clough Nurseries " />
                        </a>
                        <FooterSubSection name="Holden Clough" footerItems={holdenCloughItems} />
                        <FooterSubSection name="Contact" footerItems={contactItems} />
                        <FooterSubSection name="Address" footerItems={addressInfo} />
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
