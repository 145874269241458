import Heading from '@components/headings/Heading';
import Checkbox from 'lib/src/components/form/Checkbox';
import Description from 'lib/src/components/typography/Description';
import { FormInputProps } from 'lib/src/types/shared/FormInputProps';
import { FC, ReactNode } from 'react';

const Checkboxes = ({
    name,
    values,
    onChange,
    options,
    headingComponent: HeadingComponent = Heading.Three,
    title = '',
    description = '',
    className = '',
}: CheckboxesInputProps) => {
    const _internalOnChange = (_: string, value: number) =>
        onChange(
            name,
            values.includes(value) ? values.filter(x => x !== value) : [...values, value],
        );

    return (
        <div className={`flex-column ${className}`}>
            <HeadingComponent>{title}</HeadingComponent>
            <div className="checkboxes">
                {options.map(x => {
                    const isActive = values.includes(x.value);
                    return (
                        <div
                            className={`checkbox-container ${isActive ? 'active' : ''}`}
                            key={x.value}
                            onClick={() => _internalOnChange('', x.value)}
                        >
                            <Checkbox
                                key={x.value}
                                name={x.label}
                                label={x.label}
                                value={isActive}
                                onChange={() => {}}
                            />
                        </div>
                    );
                })}
            </div>
            {description && <Description>{description}</Description>}
        </div>
    );
};

interface CheckboxesInputProps extends Omit<FormInputProps<number[]>, 'value'> {
    label?: string;
    values: number[];
    options: { value: number; label: string }[];
    headingComponent?: FC<{ children?: ReactNode }>;
    title?: string;
    description?: string;
    className?: string;
}

export default Checkboxes;
