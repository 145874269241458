import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Account from '@pages/account/Account';
import DefaultRedirect from './DefaultRedirect';
import Orders from '@pages/orders/Orders';
import Order from '@pages/order/Order';

const AccountRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Account />
            </Route>
            <Route exact path={`${path}/orders`}>
                <Orders />
            </Route>
            <Route exact path={`${path}/orders/:id`}>
                <Order />
            </Route>
            <DefaultRedirect to="/" />
        </Switch>
    );
};

export default AccountRoutes;
