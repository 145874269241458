import { ComponentProps } from 'react';

const Loading = ({ size = 'medium', ...props }: LoadingProps & ComponentProps<'div'>) => (
    <div className={`loading ${size}`} {...props}>
        <i className="fal fa-spinner fa-spin"></i>
    </div>
);

interface LoadingProps {
    size?: 'large' | 'medium' | 'small';
}

export default Loading;
