import { Heading1 } from '@components/headings/Heading';
import Description from 'lib/src/components/typography/Description';
import ActionButton from 'lib/src/components/button/ActionButton';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import { useSelectedBedID, useSelectedOrderGuid } from '@utils/atoms';

const Success = () => {
    const history = useHistory();
    const { createOrder, isPosting } = useContext(QuestionnaireContext);
    const [, setGuid] = useSelectedOrderGuid();
    const [, setBedID] = useSelectedBedID();

    useEffect(() => {
        setGuid(null);
        setBedID(null);
    }, [setBedID, setGuid]);
    const handleCreateNewOrder = () => {
        createOrder().then(() => {
            history.push('/questionnaire');
        });
    };
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);

    const orderId = queryParams.get('guid') || 'N/A';

    return (
        <div id="questionnaire" className="height-full">
            <main className="container flex-column align-center justify-between">
                <div className="text-center">
                    <Heading1>Thank you!</Heading1>
                    <Description>
                        Your order reference: <strong>{`#${orderId}`}</strong>
                        <br />A summary will shortly be sent to your email address.
                        <br />
                        <br />
                        If you have any questions, please contact us at{' '}
                        <a
                            href={`mailto:info@holdenclough.com?subject=Order reference: #${orderId}`}
                        >
                            info@holdenclough.com
                        </a>
                    </Description>
                </div>

                <ButtonRow>
                    <ActionButton onClick={handleCreateNewOrder} isPosting={isPosting}>
                        Start new order
                    </ActionButton>

                    <LinkButton href="/" source="positive">
                        Go to homepage
                    </LinkButton>
                </ButtonRow>
            </main>
        </div>
    );
};

export default Success;
