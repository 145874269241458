import { Link } from 'react-router-dom';

const LinkButton: React.FC<LinkButtonProps> = ({
    source = 'primary',
    href = '',
    icon,
    rightIcon,
    children,
    ariaLabel,
}) => (
    <Link to={href} className={`button ${source}`} aria-label={ariaLabel}>
        {icon && <i className={`icon far fa-fw fa-${icon}`}></i>}
        <span className="text">{children}</span>
        {rightIcon && <i className={`icon isRight far fa-fw fa-${rightIcon}`}></i>}
    </Link>
);

interface LinkButtonProps {
    children: React.ReactNode;
    source?: 'primary' | 'secondary' | 'positive' | 'negative';
    href?: string;
    icon?: string;
    rightIcon?: string;
    ariaLabel?: string;
}

export default LinkButton;
