import { Order } from 'lib/src/types/shared/app/Order';
import { useApi } from 'lib/src/utils/api';
import { useMutation, useQueryClient } from 'react-query';
import { CacheKeys } from '../../../types/query/CacheKeys';

interface PostAddBedImages {
    imageS3Keys: string[];
}

const useAddBedImages = (orderGuid: string) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const postImages = async (bedID: number, imageS3Keys: string[]) =>
        api.post<PostAddBedImages, Order>(`orders/${orderGuid}/beds/${bedID}/stage/anythingelse`, {
            imageS3Keys,
        });

    const { mutate } = useMutation(
        (variables: { bedID: number; imageS3Keys: string[] }) =>
            postImages(variables.bedID, variables.imageS3Keys),
        {
            onSuccess: () => queryClient.invalidateQueries(CacheKeys.Orders),
        },
    );

    return (bedID: number, imageS3Keys: string[]) => {
        mutate({ bedID, imageS3Keys });
    };
};

export default useAddBedImages;
