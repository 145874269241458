// alignment options are: left, center, right
const Title: React.FC<TitleProps> = ({ children, alignment = '', className = '', ...props }) => {
    return (
        <h1 className={`create-header page-title ${alignment} ${className}`} {...props}>
            {children}
        </h1>
    );
};

type TitleProps = {
    children: React.ReactNode;
    alignment?: 'left' | 'center' | 'right';
    className?: string;
} & React.ComponentProps<'h1'>;

export default Title;
