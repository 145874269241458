const Paragraph = ({
    children,
    className = '',
    fontFamily = 'playfair',
    fontStyle = 'normal',
}: Props) => {
    return <p className={`paragraph ${className} ${fontFamily} font-${fontStyle}`}>{children}</p>;
};

interface Props {
    children: React.ReactNode;
    className?: string;
    fontFamily?: 'caslon' | 'elmhurst' | 'playfair';
    fontStyle?: 'italic' | 'normal';
}

export default Paragraph;
