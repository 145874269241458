import useForm from 'lib/src/hooks/useForm';
import { MeasurementShape, MeasurementUnit } from 'lib/src/types/shared/app/GardenStyle';
import { useApi } from 'lib/src/utils/api';
import { Order } from 'lib/src/types/shared/app/Order';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useContext, useMemo } from 'react';
import { BED_SHAPE, MEASUREMENT_UNIT, WIZARD_STAGE } from 'lib/src/constants/enums';
import useMeasurementInputs from './useMeasurementInputs';

interface PostMeasurementRequest {
    measurementUnit: MeasurementUnit | null;
    shape: MeasurementShape | null;
    length: number | null;
    diameter: number | null;
    width: number | null;
    area: number | null;
    // imageS3Keys: string[];
}

export interface MeasurementForm {
    measurementUnit: MeasurementUnit | null;
    shape: MeasurementShape | null;
    length: string | null;
    diameter: string | null;
    width: string | null;
    area: string | null;
    planS3Keys: string[];
}

const useMeasurements = () => {
    const {
        isPosting,
        order,
        bed,
        postStep,
        wizardContent: { content, isFetching, error },
    } = useContext(QuestionnaireContext);

    const api = useApi();
    const [form, handleChange] = useForm<MeasurementForm>({
        shape: bed?.shape ?? null,
        measurementUnit: bed?.measurementUnit ?? MEASUREMENT_UNIT.METRES,
        width: bed?.width?.toString() ?? null,
        diameter: bed?.diameter?.toString() ?? null,
        length: bed?.length?.toString() ?? null,
        area: bed?.area?.toString() ?? null,
        planS3Keys: bed?.plans?.map(plan => plan.s3Key) ?? [],
    });

    const handleSubmit = () => {
        if (disableNext || isPosting) return;
        const postReady = () =>
            api.post<PostMeasurementRequest, Order>(
                `orders/${order?.guid}/beds/${bed?.id}/stage/measurements`,
                {
                    ...form,
                    width: form?.width ? +form.width : null,
                    diameter: form?.diameter ? +form.diameter : null,
                    length: form?.length ? +form.length : null,
                    area: form?.area ? +form.area : null,
                },
            );
        postStep(postReady);
    };

    const changeMeasurementUnit = (value: MeasurementUnit) =>
        handleChange('measurementUnit', value === form.measurementUnit ? null : value);

    const changeMeasurementShape = (value: MeasurementShape) => {
        handleChange('shape', value === form.shape ? null : value);
    };

    const measurementInputs = useMeasurementInputs({
        handleChange,
        shape: form.shape,
        diameter: form.diameter,
        length: form.length,
        width: form.width,
        measurementUnit: form.measurementUnit,
    });

    const disableNext = useMemo(() => {
        if (
            !form.shape ||
            !!measurementInputs.isBadInput ||
            !!measurementInputs.isAreaTooLargeMessage
        ) {
            return true;
        }
        if (form.shape === BED_SHAPE.RECTANGLE) {
            return !form.measurementUnit || !form.length || !form.width;
        }
        if (form.shape === BED_SHAPE.CIRCLE) {
            return !form.measurementUnit || !form.diameter;
        }
        if (form.shape === BED_SHAPE.IRREGULAR) {
            return !form.area;
        }

        return true;
    }, [
        form.diameter,
        form.length,
        form.measurementUnit,
        form.shape,
        form.width,
        form.planS3Keys.length,
        form.area,
        measurementInputs.isAreaTooLargeMessage,
        measurementInputs.isBadInput,
    ]);

    return {
        form,
        changeMeasurementUnit,
        handleSubmit,
        changeMeasurementShape,
        handleChange,
        measurementInputs,
        content: { content: content[WIZARD_STAGE.MEASURE], isFetching, error },
        disableNext,
    };
};

export default useMeasurements;
