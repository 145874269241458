import Description, { DescriptionProps } from 'lib/src/components/typography/Description';
import { WizardContentItem } from 'lib/src/types/shared/app/WizardContent';
import { ReactNode } from 'react';

const InfoMessage = ({ title, children, ...descriptionProps }: InfoMessageProps) => {
    return (
        <div className="info-message flex-row gap-4 justify-evenly">
            <div className="flex-row justify-center align-center">
                <div className="info-i flex-row justify-center align-center">
                    <i className="fal fa-info-circle"></i>
                </div>
            </div>
            <div className="flex-column justify-center width-12">
                <div className="small-heading">
                    <em>A simple guide on how to measure your bed</em>
                </div>
                <Description {...descriptionProps}>{children}</Description>
            </div>
        </div>
    );
};

InfoMessage.Wizard = ({ mainText, secondaryText }: WizardContentItem) => (
    <InfoMessage title={mainText}>{secondaryText}</InfoMessage>
);

interface InfoMessageProps extends Omit<DescriptionProps, 'children'> {
    children?: ReactNode;
}

export default InfoMessage;
