import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useSelectedOrderGuid } from '@utils/atoms';
import { APIError } from 'lib/src/types/APIError';
import { useApi } from 'lib/src/utils/api';
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

const useDeleteBed = (productID: number) => {
    const history = useHistory();
    const api = useApi();
    const [showDeleteBedModal, setShowDeleteBed] = useState(false);

    const [guid] = useSelectedOrderGuid();

    const { forceRefetchOrder } = useContext(QuestionnaireContext);

    const deleteBed = () => api.delete<void>(`orders/${guid}/beds/${productID}`);

    const { mutate, isLoading, error } = useMutation<void, APIError>(deleteBed, {
        onSuccess: response => handleSuccess(response),
    });

    const handleSuccess = (response: any) => {
        if (!response) {
            return history.replace('/');
        } else {
            forceRefetchOrder();
        }
    };

    return {
        handleSubmit: mutate,
        isPosting: isLoading,
        error,
        showDeleteBedModal,
        setShowDeleteBed,
    };
};

export default useDeleteBed;
