import { Order } from 'lib/src/types/shared/app/Order';
import { Bed } from 'lib/src/types/shared/app/Bed';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi } from 'lib/src/utils/api';
import { stepAtom, useSelectedBedID, useSelectedOrderGuid } from '@utils/atoms';
import { APIError } from 'lib/src/types/APIError';
import { WizardContentItem, WizardStage } from 'lib/src/types/shared/app/WizardContent';
import { WIZARD_STAGE } from 'lib/src/constants/enums';
import { UseMutationResult, useMutation, useQuery } from 'react-query';
import { CacheKeys } from '../../types/query/CacheKeys';
import { Product } from 'lib/src/types/shared/app/Product';
import { isEmpty } from 'lib/src/utils/generic';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import { useAtom } from 'jotai';

type Response<T> = {
    content: T;
    isFetching: boolean;
    error: APIError | null;
};

interface QuestionnaireContextProps {
    order: Order | null;
    bed: Bed | null;
    step: WizardStage | null;
    postStep: (fn: (...args: any) => Promise<Order>) => void;
    setStep: (step: WizardStage) => void;
    goToPreviousStep: () => void;
    createOrder: () => Promise<void>;
    forceRefetchOrder: () => void;
    changeBed: (id: number) => void;
    addBedMutation: UseMutationResult<Order, APIError, void, unknown> | null;
    error: APIError | null;
    isPosting: boolean;
    isFetching: boolean;
    selectedOrderGuid: string;
    wizardContent: Response<Record<WizardStage, WizardContentItem[]>>;
    products: Response<Product[]>;
}

const getDefaultWizardContent = (): Record<WizardStage, WizardContentItem[]> => ({
    [WIZARD_STAGE.READY]: [],
    [WIZARD_STAGE.SUNLIGHT]: [],
    [WIZARD_STAGE.SOIL]: [],
    [WIZARD_STAGE.STYLE]: [],
    [WIZARD_STAGE.MEASURE]: [],
    [WIZARD_STAGE.CONTEXT]: [],
    [WIZARD_STAGE.ANYTHING_ELSE]: [],
    [WIZARD_STAGE.SUMMARY]: [],
    [WIZARD_STAGE.CHECKOUT]: [],
});

export const QuestionnaireContext = React.createContext<QuestionnaireContextProps>({
    order: null,
    bed: null,
    postStep: () => () => {},
    setStep: () => {},
    goToPreviousStep: () => {},
    createOrder: async () => {},
    forceRefetchOrder: () => {},
    changeBed: () => {},
    step: WIZARD_STAGE.READY,
    addBedMutation: null,
    error: null,
    isPosting: false,
    isFetching: false,
    selectedOrderGuid: '',
    products: {
        content: [],
        isFetching: false,
        error: null,
    },
    wizardContent: {
        content: getDefaultWizardContent(),
        isFetching: false,
        error: null,
    },
});

const QuestionnaireProvider = ({ children }: { children: React.ReactNode }) => {
    const isLoggedIn = useIsLoggedIn();
    const [order, setOrder] = useState<Order | null>(null);
    const [step, setStep] = useAtom(stepAtom);
    const [apiError, setApiError] = useState<APIError | null>(null);
    const [selectedOrderGuid, setSelectedOrderGuid] = useSelectedOrderGuid();
    const [isPosting, setIsPosting] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [selectedBedId, setSelectedBedId] = useSelectedBedID();
    const [selectedBedIndex, setSelectedBedIndex] = useState<number>(0);

    const bed = useMemo(
        () => order?.beds.find(bed => bed.id === selectedBedId) || null,
        [order, selectedBedId],
    );
    const api = useApi();

    const handleUpdateOrder = useCallback(
        (order: Order) => {
            setOrder(order);
            setSelectedOrderGuid(order.guid);
            if (!selectedBedId) setSelectedBedId(order.beds[selectedBedIndex].id);
        },
        [selectedBedId, selectedBedIndex, setSelectedBedId, setSelectedOrderGuid],
    );
    const createOrder = useCallback(async () => {
        console.log('creating order', apiError?.message);
        setIsPosting(true);
        api.post<Record<string, never>, Order>('orders', {})
            .then(order => {
                handleUpdateOrder(order);
                setOrder(order);
                setSelectedOrderGuid(order.guid);
                setSelectedBedId(order.beds[selectedBedIndex].id);
                setStep(order.beds[selectedBedIndex].currentStage);
            })
            .catch(e => {
                console.log({ e });
                setApiError(e as APIError);
            })
            .finally(() => setIsPosting(false));
    }, [
        apiError?.message,
        api,
        handleUpdateOrder,
        setSelectedOrderGuid,
        setSelectedBedId,
        selectedBedIndex,
        setStep,
    ]);

    // Initial fetch
    useEffect(() => {
        if (selectedOrderGuid && order?.guid !== selectedOrderGuid && !isFetching) {
            setIsFetching(true);
            api.get<Order>(`orders/${selectedOrderGuid}`)
                .then(handleUpdateOrder)
                .catch(() => setSelectedOrderGuid(null))
                .finally(() => setIsFetching(false));
        } else if (!selectedOrderGuid && !isPosting && !isFetching) {
            if (!isLoggedIn && !apiError) {
                createOrder();
            } else {
                api.get<Order[]>('orders')
                    .then(orders => {
                        if (orders.length > 0) {
                            const incompleteOrder = orders.find(order => !order.isCompleted);

                            if (!!incompleteOrder && !isEmpty(incompleteOrder)) {
                                return setSelectedOrderGuid(incompleteOrder.guid);
                            }
                        }

                        createOrder();
                    })
                    .catch(console.error);
            }
        }
    }, [
        api,
        handleUpdateOrder,
        order,
        selectedOrderGuid,
        setSelectedOrderGuid,
        isFetching,
        isPosting,
        createOrder,
        isLoggedIn,
    ]);

    useEffect(() => {
        if (order?.isCompleted) {
            setSelectedOrderGuid(null);
            setSelectedBedId(null);
        }
    }, [order, setSelectedBedId, setSelectedOrderGuid]);

    const fetchWizardContent = () =>
        api.get<Record<WizardStage, WizardContentItem[]>>(`wizard/content`);
    const {
        data: wizardContent,
        isFetching: wizardContentIsFetching,
        error: wizardContentError,
    } = useQuery<Record<WizardStage, WizardContentItem[]>, APIError>(
        CacheKeys.WizardContent,
        fetchWizardContent,
        {
            refetchOnWindowFocus: false,
        },
    );

    const fetchProducts = () => api.get<Product[]>(`wizard/products`);
    const {
        data: products,
        isFetching: productsIsFetching,
        error: productsError,
    } = useQuery<Product[], APIError>(CacheKeys.WizardProducts, fetchProducts, {
        refetchOnWindowFocus: false,
    });

    const postStep = useCallback(
        async (stepRequest: (...args: any) => Promise<Order>) => {
            try {
                setIsPosting(true);
                setApiError(null);
                const updatedOrder = await stepRequest();
                setOrder(updatedOrder);
                const updatedBed = updatedOrder.beds.find(bed => bed.id === selectedBedId);
                if (updatedBed && step !== updatedBed.currentStage) {
                    setStep(((step ?? 1) + 1) as WizardStage);
                }
            } catch (e) {
                setApiError(e as APIError);
            } finally {
                setIsPosting(false);
            }
        },
        [selectedBedId, setStep, step],
    );

    const goToPreviousStep = useCallback(() => {
        if (!step) return;
        const steps = Object.values(WIZARD_STAGE).sort();

        const minStep = steps[0];
        const prevStep = (step - 1) as WizardStage;
        if (prevStep >= minStep) setStep(prevStep);
    }, [setStep, step]);

    const forceRefetchOrder = useCallback(() => {
        setIsFetching(true);
        api.get<Order>(`orders/${selectedOrderGuid}`)
            .then(handleUpdateOrder)
            .catch(() => setSelectedOrderGuid(null))
            .finally(() => setIsFetching(false));
    }, [api, handleUpdateOrder, selectedOrderGuid, setSelectedOrderGuid]);

    const addBed = () => api.post<void, Order>(`Orders/${selectedOrderGuid}/beds`, undefined);

    const addBedMutation = useMutation<Order, APIError>(() => addBed(), {
        onSuccess: order => {
            if (!order) return;
            const index = order.beds.findIndex(x => !x.isConfirmed) ?? -1;
            const foundIndex = index === -1 ? 0 : index;
            setOrder(order);
            setSelectedOrderGuid(order.guid);
            setSelectedBedId(order.beds[foundIndex].id);
            setSelectedBedIndex(foundIndex);
            setStep(WIZARD_STAGE.READY);
        },
    });

    const changeBed = useCallback(
        (id: number) => {
            if (!order) return;
            const index = order.beds.findIndex(x => x.id === id) ?? -1;
            const foundIndex = index === -1 ? 0 : index;
            setSelectedBedId(order.beds[foundIndex].id);
            setSelectedBedIndex(foundIndex);
            setStep(WIZARD_STAGE.READY);
        },
        [order, setSelectedBedId, setStep],
    );

    return (
        <QuestionnaireContext.Provider
            value={{
                order,
                bed,
                step,
                selectedOrderGuid,
                wizardContent: {
                    content: wizardContent ?? getDefaultWizardContent(),
                    isFetching: wizardContentIsFetching,
                    error: wizardContentError,
                },
                products: {
                    content: products ?? [],
                    isFetching: productsIsFetching,
                    error: productsError,
                },
                addBedMutation,
                postStep,
                setStep,
                goToPreviousStep,
                createOrder,
                forceRefetchOrder,
                changeBed,
                error: apiError,
                isPosting,
                isFetching,
            }}
        >
            {children}
        </QuestionnaireContext.Provider>
    );
};

export default QuestionnaireProvider;
