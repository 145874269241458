import DescriptionRadioButton from '../../buttons/DescriptionRadioButton';
import useSunlight from './hooks/useSunlight';
import QuestionnaireButtonRow from '@components/questionnaire/containers/QuestionnaireButtonRow';
import Form from 'lib/src/components/form/Form';
import ProgressButton from '@components/questionnaire/buttons/ProgressButton';
import { SUNLIGHT } from 'lib/src/constants/enums';
import { WizardContent } from '@components/questionnaire/WizardContentType';
import FlowerBackground from '@components/misc/FlowerBackground';
import SaveAndContinue from '@pages/questionnaire/SaveAndContinue';
import PageMeta from 'lib/src/components/pageMeta/PageMeta';

const { MORNING, AFTERNOON, ALL_DAY, NEVER } = SUNLIGHT;

const Sunlight = () => {
    const {
        form: { sunlight },
        handleSubmit,
        changeSunlight,
        content,
        isFetching,
        error,
        disableNext,
    } = useSunlight();

    return (
        <>
            <PageMeta title="Sunlight" />

            <section className="Sunlight container flex-column gap-3">
                <WizardContent.Before content={content} isLoading={isFetching} error={error} />
                <Form onSubmit={handleSubmit} omitButtons>
                    <FlowerBackground />
                    <div className="flex-column gap-3">
                        <QuestionnaireButtonRow>
                            <DescriptionRadioButton
                                className={`${sunlight === MORNING ? 'active' : ''}`}
                                title="Morning"
                                description="I get a splash of sunshine with my morning tea."
                                onClick={() => changeSunlight(MORNING)}
                                src="/images/drawings/8.webp"
                                alt="morning"
                            />
                            <DescriptionRadioButton
                                className={`${sunlight === ALL_DAY ? 'active' : ''}`}
                                title="All Day"
                                description="When the sun shines, it shines on this bed."
                                onClick={() => changeSunlight(ALL_DAY)}
                                src="/images/drawings/9.webp"
                                alt="all day"
                            />
                            <DescriptionRadioButton
                                className={`${sunlight === AFTERNOON ? 'active' : ''}`}
                                title="Afternoon"
                                description="We just catch some rays as the sun goes down."
                                onClick={() => changeSunlight(AFTERNOON)}
                                src="/images/drawings/10.webp"
                                alt="afternoon"
                            />
                            <DescriptionRadioButton
                                className={`${sunlight === NEVER ? 'active' : ''}`}
                                title="Never"
                                description="This bed is in dappled or full shade all day long."
                                onClick={() => changeSunlight(NEVER)}
                                src="/images/drawings/11.webp"
                                alt="never"
                            />
                        </QuestionnaireButtonRow>
                        <ProgressButton isNextDisabled={disableNext} />
                        <SaveAndContinue />

                        <WizardContent.Between
                            content={content}
                            isLoading={isFetching}
                            error={error}
                        />
                        <WizardContent.After
                            content={content}
                            isLoading={isFetching}
                            error={error}
                        />
                    </div>
                </Form>
            </section>
        </>
    );
};

export default Sunlight;
