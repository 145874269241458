import Currency from 'lib/src/utils/currency';
import { Order } from 'lib/src/types/shared/app/Order';
import { memo, useContext, useMemo } from 'react';
import { ProductOrder } from 'lib/src/types/shared/app/Product';
import { useApi } from 'lib/src/utils/api';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useMutation } from 'react-query';
import { APIError } from 'lib/src/types/APIError';

const SummaryAccordionOptionalExtra = ({ id, name, price, quantity }: ProductOrder) => {
    // Commented out add to order functionality for now as they may want it readding

    const formattedPrice = useMemo(
        () => Currency.fromPennies(price).display({ fixedPoint: 2 }),
        [price],
    );

    // const { selectedOrderGuid, forceRefetchOrder } = useContext(QuestionnaireContext);

    // const api = useApi();
    // const removeOptionalExtra = () =>
    //     api.delete<void>(`Orders/${selectedOrderGuid}/products/${id}`);

    // const addToOrderEndpoint = () => api.post(`orders/${selectedOrderGuid}/products/${id}`, {});

    // const { mutate: handleRemove } = useMutation<void, APIError>(() => removeOptionalExtra(), {
    //     onSuccess: () => forceRefetchOrder(),
    // });

    // const { mutate: handleAdd } = useMutation<unknown, APIError>(() => addToOrderEndpoint(), {
    //     onSuccess: () => {
    //         forceRefetchOrder();
    //     },
    // });

    return (
        <div className="flex-row justify-between">
            <div className="flex-row gap-1">
                {/* <div
                    className="remove-optional-extra cursor-pointer"
                    onClick={() => handleRemove()}
                >
                    <i className="fal fa-minus-circle"></i>
                </div>{' '} */}
                <div className="name">{name}</div>{' '}
                {/* <div className="remove-optional-extra cursor-pointer" onClick={() => handleAdd()}>
                    <i className="fal fa-plus-circle"></i>
                </div> */}
            </div>
            <strong>
                {formattedPrice} x {quantity}
            </strong>
        </div>
    );
};

const SummaryAccordionOptionalExtras = ({ order }: SummaryAccordionOptionalExtrasProps) => {
    return (
        <div className="accordion-optional-extras">
            <p>
                <strong>Additional Products</strong>
            </p>
            {order?.products.map(x => (
                <SummaryAccordionOptionalExtra key={x.id} {...x} />
            ))}
        </div>
    );
};

interface SummaryAccordionOptionalExtrasProps {
    order: Order | null;
}

export default memo(SummaryAccordionOptionalExtras);
