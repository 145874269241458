import { useWebLogout } from '@utils/jwt';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';

import ActionButton from 'lib/src/components/button/ActionButton';
import { Redirect } from 'react-router-dom';
import Heading from '@components/headings/Heading';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import { useDecodedJwtToken } from 'lib/src/utils/jwt';
import Description from 'lib/src/components/typography/Description';
import { tryParseJson } from 'lib/src/utils/generic';

const accountLinks = [
    {
        label: 'My Orders',
        href: '/account/orders',
        description: 'View your order history',
    },
];

const Account = () => {
    const isLoggedIn = useIsLoggedIn();
    const logout = useWebLogout();
    const jwt = useDecodedJwtToken<{
        FirstName: string;
        LastName: string;
    }>();

    const firstName = tryParseJson(jwt?.FirstName);
    const lastName = tryParseJson(jwt?.LastName);

    if (!isLoggedIn) {
        return <Redirect to={{ pathname: '/', state: { prevPath: '/account' } }} />;
    }

    return (
        <main className="container height-full">
            <div id="account-page" className="page-padding height-full flex-column">
                <Heading.Two>My Account</Heading.Two>
                <Heading.Three>Details</Heading.Three>
                {firstName && (
                    <>
                        <Heading.Four>First Name</Heading.Four>
                        <Description>{firstName}</Description>
                    </>
                )}
                {lastName && (
                    <>
                        <Heading.Four>Last Name</Heading.Four>
                        <Description>{lastName}</Description>
                    </>
                )}
                <hr />

                <div className="flex flex-column justify-between">
                    <div className="account-links-container">
                        {accountLinks.map(({ label, description, href }, i) => (
                            <div className="account-link" key={i}>
                                <Heading.Three>{label}</Heading.Three>
                                <a href={href}>{description}</a>
                            </div>
                        ))}
                    </div>

                    <ButtonRow alignment="left">
                        <ActionButton onClick={() => logout()}>Logout</ActionButton>
                    </ButtonRow>
                </div>
            </div>
        </main>
    );
};

export default Account;
