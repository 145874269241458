import ActionButton from 'lib/src/components/button/ActionButton';
import { S3_URL } from '../../../../config';
import Loading from '@components/loading/Loading';
import useSummaryOptionalExtra, {
    SummaryOptionalExtraProps,
} from './hooks/useSummaryOptionalExtra';
import Modal from 'lib/src/components/modal/Modal';
import Heading from '@components/headings/Heading';
import Description from 'lib/src/components/typography/Description';
import { useContext } from 'react';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';

const SummaryOptionalExtra = (props: SummaryOptionalExtraProps) => {
    const { alt, s3Key, title, description } = props;
    const { priceStr, isPosting, error, handleAdd, handleRemove, onPressViewDetails, showModal } =
        useSummaryOptionalExtra(props);
    const { order } = useContext(QuestionnaireContext);

    const quantity = order?.products.find(x => x.id === props.id)?.quantity || 0;

    return (
        <div className="summary-optional-extra flex-column">
            <div className="image-wrapper">
                <img
                    src={`${S3_URL}/${s3Key}`}
                    alt={alt}
                    className="width-12 cursor-pointer"
                    onClick={onPressViewDetails}
                ></img>
            </div>
            <div className="summary-optional-extra-description">
                <div className="description-element">{title}</div>
                <div className="description-element">{priceStr}</div>
                <div className="flex-row summary-button-row">
                    <ActionButton source="secondary" onClick={onPressViewDetails} type="button">
                        View details
                    </ActionButton>

                    <div className="plus-minus-button">
                        <button onClick={() => handleRemove()}>
                            <i className="fas fa-minus"></i>
                        </button>

                        <span>{quantity}</span>

                        <button onClick={() => handleAdd()}>
                            <i className="fas fa-plus"></i>
                        </button>
                    </div>
                </div>

                {error && (
                    <p className="form-error">
                        Error: {error.response.status} - {error.message}
                    </p>
                )}

                {showModal && (
                    <Modal onClose={onPressViewDetails} size="large" extraClassname="summary-modal">
                        <div className="summary-modal-absolute-container left">
                            <img src={`${S3_URL}/${s3Key}`} alt={alt} className="width-12"></img>
                        </div>
                        <div className="summary-modal-absolute-container right">
                            <div
                                id="questionnaire"
                                className="flex-column align-left width-12 height-12 justify-between"
                            >
                                <div className="flex-row width-12 justify-between">
                                    <div className="flex-column gap-1 width-12 text-left">
                                        <Heading.Three>{title}</Heading.Three>
                                        <Heading.Four>{priceStr}</Heading.Four>
                                        <Description>{description}</Description>
                                    </div>
                                </div>
                                <div className="flex-row width-12 justify-between gap-1">
                                    <ActionButton source="secondary" onClick={onPressViewDetails}>
                                        Back
                                    </ActionButton>
                                    <ActionButton source="primary" onClick={() => handleAdd()}>
                                        {isPosting ? (
                                            <Loading style={{ margin: '0 30px' }} />
                                        ) : (
                                            'Add to order'
                                        )}
                                    </ActionButton>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default SummaryOptionalExtra;
