import { APIError } from 'lib/src/types/APIError';
import { useApi } from 'lib/src/utils/api';
import { useQuery } from 'react-query';
import { CacheKeys } from '../types/query/CacheKeys';
import { PauseOrders } from '../types/shared/PauseOrders';

const useFetchPauseOrders = () => {
    const api = useApi();

    const fetchIsPaused = () => api.get<PauseOrders>('Orders/check-is-paused');

    const { data, error, isFetching } = useQuery<PauseOrders, APIError>(
        CacheKeys.PauseOrders,
        fetchIsPaused,
    );

    const isPaused = data?.isPaused ?? false;

    return {
        isPaused,
        pauseError: error?.message,
        pauseFetching: isFetching,
    };
};

export default useFetchPauseOrders;
