import FormField from 'lib/src/components/form/FormField';
import useFieldValidation from 'lib/src/hooks/useFieldValidation';
import { FormInputProps } from 'lib/src/types/shared/FormInputProps';
import React, { SyntheticEvent, useCallback } from 'react';

const MeasurementInput: React.FC<MeasurementInputProps> = ({
    name,
    value,
    onChange,
    label = '',
    placeholder = '',
    required = false,
    validationRegExp,
    minLength,
    maxLength,
    disabled,
    customValidate,
    ariaLabel = 'Enter text',
    measurementValue = 'm',
    superscript = '',
}) => {
    const memoizedValidate = useCallback(_validate, [minLength, maxLength, validationRegExp]);
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
        extendedValidate: memoizedValidate,
    });

    return (
        <FormField name={name} label={label} required={required} error={error}>
            <div className="measurements-input">
                <input
                    type="text"
                    className={`form-input text-area ${error ? 'error' : ''}`}
                    placeholder={placeholder}
                    name={name}
                    id={name}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                    aria-label={ariaLabel}
                    aria-required={required ? 'true' : 'false'}
                />
                <div className="measurements-value">
                    {measurementValue}
                    {superscript && <sup>{superscript}</sup>}
                </div>
            </div>
        </FormField>
    );

    function handleBlur() {
        showError();
    }

    function handleChange(e: SyntheticEvent) {
        e.preventDefault();

        const newVal = (e.target as HTMLInputElement).value;

        onChange(name, newVal);
    }

    function _validate(val: string) {
        if (!val) return;

        if (minLength && val.length < minLength) {
            return `Value must have at least ${minLength} characters.`;
        }
        if (maxLength && val.length > maxLength) {
            return `Value cannot have more than ${maxLength} characters.`;
        }
        if (validationRegExp && !RegExp(validationRegExp).test(val)) {
            return 'Invalid value provided.';
        }
    }
};

interface MeasurementInputProps extends FormInputProps<string> {
    placeholder?: string;
    label?: string;
    validationRegExp?: string;
    minLength?: number;
    maxLength?: number;
    measurementValue: string;
    superscript?: string;
}

export default MeasurementInput;
