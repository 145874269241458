import { CSSProperties, ComponentProps, FC } from 'react';

const ImageButton: FC<ImageButtonProps> = ({
    text,
    src,
    className = '',
    type = 'button',
    imageClass = '',
    isActive = false,
    ...props
}) => {
    const filter: CSSProperties = isActive
        ? { filter: 'brightness(0.5) contrast(1.6) invert(1)' }
        : {};
    return (
        <button
            className={`questionnaire-image-button flex-column gap-3 align-center ${
                isActive ? 'active' : ''
            } ${className}`}
            type={type}
            {...props}
        >
            <img style={filter} src={src} alt={`${text}-button `} className={imageClass}></img>
            <span className="text text-center">{text}</span>
        </button>
    );
};

type ImageButtonProps = Omit<ComponentProps<'button'>, 'children'> & {
    text: string;
    src: string;
    isActive?: boolean;
    imageClass?: string;
};

export default ImageButton;
