import {
    BedShape,
    BedType,
    MeasurementShape,
    MeasurementUnit,
    Soil,
    Sunlight,
} from '../types/shared/app/GardenStyle';
import { WizardStage } from '../types/shared/app/WizardContent';
import {
    BED,
    BED_SHAPE,
    MEASUREMENT_SHAPE,
    MEASUREMENT_UNIT,
    SOIL_TYPES,
    SUNLIGHT,
    WIZARD_STAGE,
} from './enums';

export const MEASUREMENT_SHAPE_TEXT: Record<MeasurementShape, string> = {
    [MEASUREMENT_SHAPE.RECTANGLE]: 'Rectangular/Square',
    [MEASUREMENT_SHAPE.CIRCULAR]: 'Circular',
    [MEASUREMENT_SHAPE.IRREGULAR]: 'Irregular (Upload Files)',
};

export const MEASUREMENT_UNIT_MAPPINGS: Record<MeasurementUnit, string> = {
    [MEASUREMENT_UNIT.MILLIMETRES]: 'mm',
    [MEASUREMENT_UNIT.CENTIMETRES]: 'cm',
    [MEASUREMENT_UNIT.METRES]: 'm',
    [MEASUREMENT_UNIT.INCHES]: 'in',
    [MEASUREMENT_UNIT.FEET]: 'ft',
    [MEASUREMENT_UNIT.YARDS]: 'yd',
};

export const SOIL_VALUES: Record<Soil, string> = {
    [SOIL_TYPES.CLAY]: 'Clay',
    [SOIL_TYPES.SOIL]: 'Soil',
    [SOIL_TYPES.SANDY]: 'Sandy',
} as const;

export const SUNLIGHT_VALUES: Record<Sunlight, string> = {
    [SUNLIGHT.MORNING]: 'Morning',
    [SUNLIGHT.AFTERNOON]: 'Afternoon',
    [SUNLIGHT.EVENING]: 'Evening',
    [SUNLIGHT.ALL_DAY]: 'All Day',
    [SUNLIGHT.NEVER]: 'Never',
} as const;

export const BED_VALUES: Record<BedType, string> = {
    [BED.FULL_BED]: 'Full Bed',
    [BED.SOIL_BED]: 'Soil Bed',
    [BED.NO_BEDS]: 'No Beds',
} as const;

export const BED_SHAPE_VALUES: Record<BedShape, string> = {
    [BED_SHAPE.RECTANGLE]: 'Rectangle',
    [BED_SHAPE.CIRCLE]: 'Circle',
    [BED_SHAPE.IRREGULAR]: 'Irregular',
} as const;

export const WIZARD_STAGE_VALUES: Record<WizardStage, string> = {
    [WIZARD_STAGE.READY]: 'Ready',
    [WIZARD_STAGE.SUNLIGHT]: 'Sunlight',
    [WIZARD_STAGE.SOIL]: 'Soil type',
    [WIZARD_STAGE.STYLE]: 'Garden style',
    [WIZARD_STAGE.MEASURE]: 'Measurements',
    [WIZARD_STAGE.CONTEXT]: 'Context',
    [WIZARD_STAGE.ANYTHING_ELSE]: 'Anything else',
    [WIZARD_STAGE.SUMMARY]: 'Summary',
    [WIZARD_STAGE.CHECKOUT]: 'Checkout',
};
