import { Redirect, Route, useLocation } from 'react-router-dom';
import { useJwt, validateJWT } from 'lib/src/utils/jwt';
import { Suspense } from 'react';
import Loading from '@components/loading/Loading';

const SubRouter: React.FC<SubRouterProps> = ({ path, children, auth }) => {
    const location = useLocation();
    const jwt = useJwt();
    if (auth) {
        const isValidToken = validateJWT(jwt);

        if (!isValidToken) {
            return <Redirect to="/auth/login" from={location.pathname} />;
        }
    }
    return (
        <Suspense fallback={<Loading />}>
            <Route path={path}>{children}</Route>
        </Suspense>
    );
};

interface SubRouterProps {
    children: React.ReactNode;
    path: string;
    auth?: boolean;
    exact?: boolean;
}
export default SubRouter;
