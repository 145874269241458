import useForm from 'lib/src/hooks/useForm';
import { Sunlight } from 'lib/src/types/shared/app/GardenStyle';
import { useApi } from 'lib/src/utils/api';
import { Order } from 'lib/src/types/shared/app/Order';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useContext } from 'react';
import { WIZARD_STAGE } from 'lib/src/constants/enums';

interface PostSunlightRequest {
    sunlight: Sunlight | null;
}

const useSunlight = () => {
    const { order, bed, isPosting, postStep } = useContext(QuestionnaireContext);
    const [form, handleChange] = useForm<PostSunlightRequest>({
        sunlight: bed?.sunlight ?? null,
    });

    const api = useApi();

    const disableNext = !form.sunlight;
    const handleSubmit = () => {
        if (disableNext || isPosting) return;
        const postSunlight = () =>
            api.post<PostSunlightRequest, Order>(
                `orders/${order?.guid}/beds/${bed?.id}/stage/sunlight`,
                form,
            );
        postStep(postSunlight);
    };

    const changeSunlight = (value: Sunlight) =>
        handleChange('sunlight', value === form.sunlight ? null : value);

    const {
        wizardContent: { content, isFetching, error },
    } = useContext(QuestionnaireContext);

    return {
        form,
        changeSunlight,
        handleSubmit,
        content: content[WIZARD_STAGE.SUNLIGHT],
        isFetching,
        error,
        disableNext,
    };
};

export default useSunlight;
