import useWindowSize from 'lib/src/hooks/useWindowSize';
import { ComponentProps, useEffect, useRef, useState } from 'react';

const FlowerBackground = ({ className, ...extraProps }: FlowerBackgroundProps) => {
    const { width } = useWindowSize();
    const ref = useRef<HTMLDivElement>(null);
    const [left, setLeft] = useState(0);

    useEffect(() => {
        if (ref.current) setLeft(-ref.current.getBoundingClientRect().x);
    }, [width]);

    return (
        <div ref={ref} className={`flower-background ${className}`} {...extraProps}>
            <img
                id="flower-background-left"
                style={{ transform: `translate3d(${left}px, 0, 0)` }}
                className="left"
                src="/images/FlowerLeft.svg"
                alt="left-flower-backdrop"
            />
            <img
                id="flower-background-right"
                style={{
                    transform: `translate3d(${left}px, 0, 0)`,
                }}
                className="right"
                src="/images/FlowerRight.svg"
                alt="right-flower-backdrop"
            />
        </div>
    );
};

type FlowerBackgroundProps = {
    className?: string;
} & ComponentProps<'div'>;

export default FlowerBackground;
