import Heading from '@components/headings/Heading';
import Description, { DescriptionProps } from 'lib/src/components/typography/Description';
import { WizardContentItem } from 'lib/src/types/shared/app/WizardContent';
import { FC, ReactNode } from 'react';
import { S3_URL } from '../../../config';

const Quote = ({
    children,
    src = '/images/Pots.webp',
    alt = 'Pots',
    headingComponent: HeadingComponent = Heading.Three,
    author,
    ...descriptionProps
}: QuoteProps) => {
    return (
        <div className="quote flex-row gap-3 justify-center">
            <div className="flex-row justify-center">
                <span className="quote-character">&ldquo;</span>
                <img src={src} alt={alt}></img>
            </div>
            <div className="quote-text flex-column justify-center width-10">
                <Description {...descriptionProps}>{children}</Description>
                <span className="author">{author}</span>
            </div>
        </div>
    );
};

Quote.Wizard = ({ imageS3Key, mainText, secondaryText }: WizardContentItem) => (
    <Quote src={`${S3_URL}/${imageS3Key}`} author={secondaryText ?? ''} alt={mainText}>
        {mainText}
    </Quote>
);

interface QuoteProps extends Omit<DescriptionProps, 'children'> {
    children?: ReactNode;
    headingComponent?: FC;
    author: string;
    src?: string;
    alt?: string;
}

export default Quote;
