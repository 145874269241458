import { HeadingProps } from './Heading';

const Heading4 = ({
    extraClasses = '',
    children,
    ...props
}: HeadingProps & React.ComponentProps<'h4'>) => {
    return (
        <h4 className={'heading-4 ' + extraClasses} {...props}>
            {children}
        </h4>
    );
};

export default Heading4;
