import { MEASUREMENT_SHAPE, MEASUREMENT_UNIT } from 'lib/src/constants/enums';
import Select from 'lib/src/components/form/Select';
import { onChangeFunction } from 'lib/src/types/shared/FormInputProps';
import { MeasurementForm } from './hooks/useMeasurements';
import MeasurementInput from '@components/questionnaire/inputs/MeasurementInput';
import { reverseMapperDropdown } from 'lib/src/utils/generic';
import { MeasurementShape, MeasurementUnit } from 'lib/src/types/shared/app/GardenStyle';
import useMeasurementInputs from './hooks/useMeasurementInputs';
import ImageUpload from '@components/questionnaire/inputs/ImageUpload';
import { DropdownOption } from 'lib/src/types/shared/DropdownOption';

const options = reverseMapperDropdown<MeasurementUnit>(MEASUREMENT_UNIT);

const formatInputOptions = (shape: MeasurementShape | null): DropdownOption<number>[] => {
    if (shape === MEASUREMENT_SHAPE.RECTANGLE || shape === MEASUREMENT_SHAPE.CIRCULAR) {
        return options.filter(x => x.value !== MEASUREMENT_UNIT.MILLIMETRES);
    } else if (shape === MEASUREMENT_SHAPE.IRREGULAR) {
        return options.filter(
            x => x.value === MEASUREMENT_UNIT.METRES || x.value === MEASUREMENT_UNIT.YARDS,
        );
    } else {
        return options;
    }
};

const MeasurementsInputs = ({
    measurementUnit,
    length,
    width,
    area,
    planS3Keys,
    diameter,
    handleChange,
    shape,
    measurementInputs: {
        handleChangeWithSanitize,
        isAreaTooLargeMessage,
        isBadInput,
        isCircular,
        isIrregular,
        isQuad,
        isShape,
        measurementUnitStr,
    },
}: MeasurementInputProps) => {
    const options = formatInputOptions(shape);
    return (
        <div>
            <div className="measurements-inputs flex-row justify-center gap-2">
                {/* Unit */}
                {isShape && !isIrregular && (
                    <Select
                        label="Unit"
                        name="measurementUnit"
                        value={measurementUnit}
                        options={options}
                        onChange={handleChange}
                        placeholder="Metres"
                    />
                )}
                {isShape && isIrregular && (
                    <Select
                        label="Unit"
                        name="measurementUnit"
                        value={measurementUnit}
                        options={options}
                        onChange={handleChange}
                        placeholder="Metres"
                    />
                )}
                {/* Length & Width*/}
                {isQuad && (
                    <>
                        <MeasurementInput
                            label="Length"
                            name="length"
                            value={`${length ?? ''}`}
                            placeholder="Enter Length..."
                            onChange={handleChangeWithSanitize}
                            measurementValue={measurementUnitStr}
                        />
                        <MeasurementInput
                            label="Width"
                            name="width"
                            value={`${width ?? ''}`}
                            placeholder="Enter Width..."
                            onChange={handleChangeWithSanitize}
                            measurementValue={measurementUnitStr}
                        />
                    </>
                )}
                {isIrregular && (
                    <MeasurementInput
                        label="Area"
                        name="area"
                        value={`${area ?? ''}`}
                        placeholder="Enter area..."
                        onChange={handleChangeWithSanitize}
                        measurementValue={measurementUnitStr}
                        superscript="&#178;"
                    />
                )}
                {/* Diameter */}
                {isCircular && (
                    <MeasurementInput
                        label="Diameter"
                        name="diameter"
                        value={`${diameter ?? ''}`}
                        placeholder="Enter Diameter..."
                        onChange={handleChangeWithSanitize}
                        measurementValue={measurementUnitStr}
                    />
                )}
            </div>
            {isIrregular && (
                <ImageUpload.Multi
                    s3KeyName="planS3Keys"
                    s3Keys={planS3Keys}
                    handleChange={handleChange}
                    extraClasses="width-12"
                    choose={
                        <>
                            <i className="image-icon far fa-download"></i> Upload files
                        </>
                    }
                />
            )}
            {isBadInput && <p className="form-error">{isBadInput}</p>}
            {isAreaTooLargeMessage && <p className="form-error">{isAreaTooLargeMessage}</p>}
        </div>
    );
};

type MeasurementInputProps = MeasurementForm & {
    handleChange: onChangeFunction<any>;
    measurementInputs: ReturnType<typeof useMeasurementInputs>;
};

export default MeasurementsInputs;
