import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useContext } from 'react';

const useAddAnotherBed = () => {
    const { addBedMutation } = useContext(QuestionnaireContext);

    const handleSubmit = () => addBedMutation?.mutate();
    const isPosting = addBedMutation?.isLoading ?? false;

    return { handleSubmit, isPosting };
};

export default useAddAnotherBed;
