import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import 'lib/src/_styles/generic.scss';
import './_styles/main.scss';
import 'react-tooltip/dist/react-tooltip.css';

import { initLib } from 'lib/src/config';
import { API_URL, S3_URL } from './config';

import ErrorBoundary from 'lib/src/pages/error/ErrorBoundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
const queryClient = new QueryClient();
initLib(API_URL, S3_URL);

const container = document.getElementById('root');
const root = createRoot(container!); // eslint-disable-line

root.render(
    <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
            <Router>
                <App />
            </Router>
            <ReactQueryDevtools />
        </QueryClientProvider>
    </ErrorBoundary>,
);
