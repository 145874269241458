import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';
import EmailInput from 'lib/src/components/form/EmailInput';
import PasswordInput from 'lib/src/components/form/PasswordInput';

import { HandleSubmit } from 'src/types/shared/Functions';
import { APIError } from 'lib/src/types/APIError';
import { RegisterRequestModel, RegisterSteps } from './RegisterFormContainer';
import PhoneInput from '../../../../../lib/src/components/form/PhoneInput';

const RegisterForm: React.FC<RegisterFormProps> = ({
    formState: {
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        addressLine1,
        addressLine2,
        city,
        county,
        postcode,
        country,
    },
    confirmPassword,
    setConfirmPassword,
    handleChange,
    handleSubmit,
    isPosting,
    apiError,
    registerStep,
    setRegisterStep,
    handleButtonPress,
    validateConfirmPassword,
    isNextDisabled,
}): JSX.Element => (
    <Form onSubmit={handleSubmit} isPosting={isPosting} omitButtons apiError={apiError}>
        {registerStep === RegisterSteps.AccountDetails && (
            <>
                <FormRow>
                    <TextInput
                        name="firstName"
                        value={firstName}
                        label="First name"
                        onChange={handleChange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="lastName"
                        value={lastName}
                        label="Last name"
                        onChange={handleChange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <EmailInput
                        name="email"
                        value={email}
                        label="Email address"
                        onChange={handleChange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <PhoneInput
                        name="phoneNumber"
                        value={phoneNumber}
                        label="Phone Number"
                        onChange={handleChange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <PasswordInput
                        name="password"
                        value={password}
                        label="Password"
                        onChange={handleChange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <PasswordInput
                        name="confirmPassword"
                        value={confirmPassword}
                        label="Confirm Password"
                        onChange={(_, val) => setConfirmPassword(val)}
                        customValidate={validateConfirmPassword}
                        required
                    />
                </FormRow>
            </>
        )}

        {registerStep === RegisterSteps.AddressDetails && (
            <>
                <FormRow>
                    <TextInput
                        name="addressLine1"
                        value={addressLine1}
                        label="Address line 1"
                        onChange={handleChange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="addressLine2"
                        value={addressLine2}
                        label="Address line 2"
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="city"
                        value={city}
                        label="City"
                        onChange={handleChange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="county"
                        value={county}
                        label="County"
                        onChange={handleChange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="postcode"
                        value={postcode}
                        label="Postcode"
                        onChange={handleChange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="country"
                        value={country}
                        label="Country"
                        onChange={handleChange}
                        required
                    />
                </FormRow>
            </>
        )}

        <ButtonRow alignment="right">
            {registerStep === RegisterSteps.AddressDetails && (
                <ActionButton onClick={() => setRegisterStep(RegisterSteps.AccountDetails)}>
                    Back
                </ActionButton>
            )}
            <ActionButton
                isPosting={isPosting}
                onClick={handleButtonPress}
                type="button"
                disabled={isNextDisabled}
            >
                {registerStep === RegisterSteps.AccountDetails ? 'Next' : 'Register'}
            </ActionButton>
        </ButtonRow>
    </Form>
);

interface RegisterFormProps {
    formState: RegisterRequestModel;
    handleChange: <T>(name: keyof RegisterRequestModel, value: T) => void;
    handleSubmit: HandleSubmit;
    isPosting: boolean;
    apiError: APIError | null;
    registerStep: RegisterSteps;
    setRegisterStep: (step: RegisterSteps) => void;
    handleButtonPress: () => void;
    confirmPassword: string;
    setConfirmPassword: (value: string) => void;
    validateConfirmPassword: (value: string) => string | undefined;
    isNextDisabled: boolean;
}

export default RegisterForm;
