import { MEASUREMENT_SHAPE, MEASUREMENT_UNIT } from 'lib/src/constants/enums';
import { MeasurementUnit, MeasurementShape } from 'lib/src/types/shared/app/GardenStyle';
const { RECTANGLE, CIRCULAR, IRREGULAR } = MEASUREMENT_SHAPE;

const MAX_AREA_METER_SQUARED = 150_000;

export type BaseShape = { type: MeasurementShape; unit: MeasurementUnit };
export type Circle = BaseShape & { type: typeof CIRCULAR; diameter: number };
export type Quad = BaseShape & {
    type: typeof IRREGULAR | typeof RECTANGLE;
    length: number;
    width: number;
};

export type Shape = Circle | Quad;
export type MetreShape = Shape & { unit: typeof MEASUREMENT_UNIT.METRES };

export const UNIT_TO_METRES: Record<MeasurementUnit, (value: number) => number> = {
    [MEASUREMENT_UNIT.MILLIMETRES]: (value: number) => value / 1000,
    [MEASUREMENT_UNIT.YARDS]: (value: number) => value * 0.9144,
    [MEASUREMENT_UNIT.INCHES]: (value: number) => value * 0.0254,
    [MEASUREMENT_UNIT.FEET]: (value: number) => value * 0.3048,
    [MEASUREMENT_UNIT.CENTIMETRES]: (value: number) => value / 100,
    [MEASUREMENT_UNIT.METRES]: (value: number) => value,
};

export const getArea = (obj: Shape) => {
    if (obj.type === CIRCULAR) {
        return Math.PI * Math.pow(obj.diameter / 2, 2);
    }
    if (obj.type === RECTANGLE || obj.type === IRREGULAR) {
        return obj.length * obj.width;
    }
    return 1; // impossible edge case
};

export const unitToMetres = (unit: MeasurementUnit, value: number) => UNIT_TO_METRES[unit](value);

export const convertShapeToMetres = (obj: Shape): MetreShape => {
    if (obj.type === CIRCULAR) {
        return {
            ...obj,
            diameter: unitToMetres(obj.unit, obj.diameter),
            unit: MEASUREMENT_UNIT.METRES,
        };
    }
    if (obj.type === RECTANGLE || obj.type === IRREGULAR) {
        return {
            ...obj,
            length: unitToMetres(obj.unit, obj.length),
            width: unitToMetres(obj.unit, obj.width),
            unit: MEASUREMENT_UNIT.METRES,
        };
    }
    return obj as MetreShape;
};

export const isAreaTooLarge = {
    metres: (obj: MetreShape): boolean => getArea(obj) > MAX_AREA_METER_SQUARED,
};
