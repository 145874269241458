import React, { useMemo } from 'react';
import { ComponentProps, FC, ReactNode } from 'react';

const QuestionnaireButtonRow: FC<QuestionnaireButtonRowProps> = ({
    title,
    children,
    className = '',
    ...props
}) => {
    const length = useMemo(() => {
        if (children) {
            if (Array.isArray(children)) {
                return children.length;
            }
        }
        return 0;
    }, [children]);

    return (
        <div
            className={`flex-row gap justify-center width-12 questionnaire-button-row ${className}`}
            {...props}
        >
            {(Array.isArray(children) &&
                children.map((child, key) => {
                    return React.cloneElement(child, {
                        key,
                        style: { ...child.props.style, width: `${100 / length}%` },
                    });
                })) ||
                children}
        </div>
    );
};

interface QuestionnaireButtonRowProps extends Omit<ComponentProps<'div'>, 'children'> {
    children?: ReactNode;
}

export default QuestionnaireButtonRow;
