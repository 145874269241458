import { HeadingProps } from './Heading';

const Heading5 = ({
    extraClasses = '',
    children,
    ...props
}: HeadingProps & React.ComponentProps<'h5'>) => {
    return (
        <h5 className={'heading-5 ' + extraClasses} {...props}>
            {children}
        </h5>
    );
};

export default Heading5;
