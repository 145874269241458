import { Link } from 'react-router-dom';
import Logo from '/images/hc-logo.svg';

const Header = (): JSX.Element => {
    return (
        <header className="header">
            <div className="container flex-row justify-center align-center">
                <div className="logo flex-row align-center">
                    <Link to="/">
                        <img alt="Holden Clough Nurseries" src={Logo} />
                    </Link>
                </div>
            </div>
        </header>
    );
};

export default Header;
