import { CSSProperties } from 'react';
import { a, useSpring } from 'react-spring';
import { SoilObject } from './hooks/useSoil';
import DescriptionRadioButton from '@components/questionnaire/buttons/DescriptionRadioButton';
import Checkbox from 'lib/src/components/form/Checkbox';

const SIZE = 35;
const HEIGHT = 400;
const MOVED_DISTANCE = 100;

const SoilButton = ({
    style,
    isActive,
    extraQuestion,
    heading,
    description,
    onClick,
    alt,
    src,
    title,
}: SoilButtonProps) => {
    const showDropdown = !!extraQuestion && isActive;

    const spring = useSpring({
        bottom: showDropdown ? -SIZE : 0,
        opacity: +showDropdown,
    });

    const containerSpring = useSpring({
        height: showDropdown ? HEIGHT - MOVED_DISTANCE : HEIGHT,
        marginBottom: showDropdown ? MOVED_DISTANCE : 0,
    });
    return (
        <div className="flex-column" style={style}>
            <a.div className="height-12" style={containerSpring}>
                <DescriptionRadioButton
                    className={`${isActive ? 'active' : ''}`}
                    description={description}
                    title={heading}
                    onClick={onClick}
                    alt={alt}
                    src={src}
                />
                {!!extraQuestion && (
                    <a.div style={spring} className="soil-absolute-button">
                        <Checkbox
                            name={title}
                            label={extraQuestion.text}
                            value={extraQuestion.value as boolean}
                            onChange={extraQuestion.onChange}
                        />
                    </a.div>
                )}
            </a.div>
        </div>
    );
};

export type SoilButtonProps = SoilObject & {
    isActive: boolean;
    title: string;
    style?: CSSProperties;
    onClick: () => void;
};

export default SoilButton;
