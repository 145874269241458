import SummaryAccordion from './SummaryAccordion';
import useSummary from './hooks/useSummary';
import Currency from 'lib/src/utils/currency';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import { WizardContent } from '@components/questionnaire/WizardContentType';
import SummaryOptionalExtras from './SummaryOptionalExtras';
import Loading from '@components/loading/Loading';
import AddAnotherBed from './AddAnotherBed';
import FlowerBackground from '@components/misc/FlowerBackground';
import PageMeta from 'lib/src/components/pageMeta/PageMeta';
import useFetchPauseOrders from '../../../../hooks/useFetchPauseOrders';
import SaveAndContinue from '@pages/questionnaire/SaveAndContinue';
import EstimatedDelivery from './EstimatedDelivery';
import FormRow from 'lib/src/components/form/FormRow';
import TextInput from 'lib/src/components/form/TextInput';

const Summary = () => {
    const { isPaused } = useFetchPauseOrders();

    const {
        order,
        isFetching,
        error,
        content,
        handleSubmit,
        goToPreviousStep,
        isPosting,
        minimumOrder,
        paymentDisabled,
        form,
        handleChange,
    } = useSummary(isPaused);

    const isOrderPaused = isPaused && !order?.canBypassPause;

    return (
        <>
            <PageMeta title="Summary" />

            <section className="summary container flex-column gap-3">
                <WizardContent.Before
                    content={content.content}
                    isLoading={content.isFetching}
                    error={content.error}
                />
                <div>
                    <FlowerBackground />

                    {isFetching && !order ? (
                        <Loading size="large" />
                    ) : (
                        <div className="summary-container">
                            {!!error && <p className="form-error">{error.message}</p>}

                            <div className="summary-content flex-column">
                                <div className="flex-row justify-between">
                                    <h3>Your Wonder Garden</h3>
                                    <p className="total-cost">
                                        {Currency.fromPennies(order?.total ?? 0).display({
                                            fixedPoint: 2,
                                        })}
                                    </p>
                                </div>

                                <SummaryAccordion />
                            </div>
                            {!minimumOrder.isValid && (
                                <p className="info">
                                    Our minimum order size is {minimumOrder.value}. Why not add
                                    another bed or browse our additional items?
                                </p>
                            )}

                            <EstimatedDelivery />
                        </div>
                    )}

                    <WizardContent.Between
                        content={content.content}
                        isLoading={content.isFetching}
                        error={content.error}
                    />
                    <WizardContent.After
                        content={content.content}
                        isLoading={content.isFetching}
                        error={content.error}
                    />
                    <SummaryOptionalExtras />
                    <AddAnotherBed />

                    <div className="hear-about-us">
                        <FormRow>
                            <TextInput
                                name="howDidYouHearAboutUs"
                                value={form.howDidYouHearAboutUs}
                                onChange={handleChange}
                                label="Where did you hear about us"
                            />
                        </FormRow>
                    </div>

                    {isOrderPaused && (
                        <div
                            className="summary-container"
                            style={{ marginTop: 30, marginBottom: 30, padding: 20 }}
                        >
                            <h3>
                                Due to high traffic we've had to pause taking new orders. Please
                                feel free to save your progress and pick up where you left off in
                                the near future.
                            </h3>
                        </div>
                    )}

                    <ButtonRow alignment="center" style={{ marginTop: 30 }}>
                        <ActionButton
                            source="secondary"
                            onClick={goToPreviousStep}
                            icon="arrow-left"
                        >
                            Back
                        </ActionButton>

                        {isOrderPaused ? (
                            <SaveAndContinue />
                        ) : (
                            <ActionButton
                                source="primary"
                                onClick={handleSubmit}
                                type="button"
                                isPosting={isPosting}
                                disabled={paymentDisabled}
                                rightIcon="arrow-right"
                            >
                                Continue to payment
                            </ActionButton>
                        )}
                    </ButtonRow>
                </div>
            </section>
        </>
    );
};

export default Summary;
