import useWindowSize from 'lib/src/hooks/useWindowSize';
import { ComponentProps, memo, useMemo } from 'react';

const settings = {
    smoothness: 0.6, // 0-1
    pointsGenerated: 15,
    highestHeight: 10,
};

const smoothArray = (arr: number[], smoothFactor: number) =>
    arr.map(num => num + smoothFactor * (arr.reduce((a, b) => a + b, 0) / arr.length - num));

const WobblyBar = ({
    direction = 'top',
    fill,
    style = {},
    className = '',
    ...extraProps
}: WobblyBarProps) => {
    const { width } = useWindowSize();
    const { highestHeight } = settings;
    const objectPoints = useMemo(() => {
        const { smoothness, pointsGenerated } = settings;
        const topPoint = 100;
        const elements = [...Array(pointsGenerated)].map((_, i) => ({
            x: (i / pointsGenerated) * 100,
            y: Math.random() * topPoint,
        }));
        const linePoints = [{ x: 0, y: 0 }, ...elements, { x: topPoint, y: 0 }];
        const smoothedYPoints = smoothArray(
            linePoints.map(point => point.y),
            smoothness,
        ).map((yPoint, i) => ({ x: linePoints[i].x, y: yPoint }));
        const polyline = [{ x: 0, y: topPoint }, ...smoothedYPoints, { x: topPoint, y: topPoint }]
            .map(({ x, y }) => `${x},${y}`)
            .join(' ');

        return polyline;
    }, []);

    const height = Math.min(highestHeight, width / 150);

    return (
        <div
            className={`wobbly-${direction} ${className}`}
            style={{ height, [direction]: -(height - 1), ...style }}
            {...extraProps}
        >
            <svg viewBox="0 0 100 100" preserveAspectRatio="none" style={{ ...(fill && { fill }) }}>
                <polyline points={objectPoints} strokeLinejoin="round"></polyline>
            </svg>
        </div>
    );
};

type WobblyBarProps = {
    direction?: 'top' | 'bottom';
    fill?: string;
} & ComponentProps<'div'>;

export default memo(WobblyBar);
