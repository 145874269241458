import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { APIError } from 'lib/src/types/APIError';
import { useApi } from 'lib/src/utils/api';
import Currency from 'lib/src/utils/currency';
import { useMemo, useContext, useCallback, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';

const useSummaryOptionalExtra = ({ priceInPennies, id }: SummaryOptionalExtraProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const priceStr = useMemo(
        () =>
            Currency.fromPennies(priceInPennies).display({
                fixedPoint: 2,
            }),
        [priceInPennies],
    );

    const { selectedOrderGuid, forceRefetchOrder } = useContext(QuestionnaireContext);
    const queryClient = useQueryClient();
    const api = useApi();
    const endpoint = `orders/${selectedOrderGuid}/products/${id}`;
    const addToOrderEndpoint = () => api.post(endpoint, {});
    const removeOptionalExtra = () =>
        api.delete<void>(`Orders/${selectedOrderGuid}/products/${id}`);

    const {
        mutate: handleAdd,
        isLoading: isPosting,
        error,
    } = useMutation<unknown, APIError>(() => addToOrderEndpoint(), {
        onSuccess: () => {
            queryClient.invalidateQueries(endpoint);
            forceRefetchOrder();
        },
    });
    const { mutate: handleRemove, isLoading: isPostingRemove } = useMutation<void, APIError>(
        () => removeOptionalExtra(),
        {
            onSuccess: () => forceRefetchOrder(),
        },
    );

    const onPressViewDetails = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    return {
        priceStr,
        isPosting: isPosting || isPostingRemove,
        error,
        handleAdd,
        handleRemove,
        onPressViewDetails,
        showModal,
    };
};

export type SummaryOptionalExtraProps = {
    priceInPennies: number;
    title: string;
    s3Key: string | null;
    description: string;
    alt: string;
    id: number;
};

export default useSummaryOptionalExtra;
