import DescriptionRadioButton from '../../buttons/DescriptionRadioButton';
import useReady from './hooks/useReady';
import QuestionnaireButtonRow from '@components/questionnaire/containers/QuestionnaireButtonRow';
import Form from 'lib/src/components/form/Form';
import ProgressButton from '@components/questionnaire/buttons/ProgressButton';
import { WizardContent } from '@components/questionnaire/WizardContentType';
import FlowerBackground from '@components/misc/FlowerBackground';
import SaveAndContinue from '@pages/questionnaire/SaveAndContinue';
import PageMeta from 'lib/src/components/pageMeta/PageMeta';
import useBedContent from './hooks/useBedContent';

const Ready = () => {
    const {
        form: { bedType },
        bedSelection,
        handleSubmit,
        error,
        content: { content, isFetching: wizardContentIsFetching, error: wizardContentError },
        isPosting,
        disableNext,
    } = useReady();

    const { bedContent } = useBedContent(bedType, bedSelection);

    return (
        <>
            <PageMeta title="Ready" />
            <section className="ready container flex-column gap-3">
                <WizardContent.Before
                    content={content}
                    error={wizardContentError}
                    isLoading={wizardContentIsFetching}
                />
                <Form onSubmit={handleSubmit} apiError={error} isPosting={isPosting} omitButtons>
                    <FlowerBackground />
                    <div className="flex-column gap-3">
                        <QuestionnaireButtonRow>
                            {bedContent.map(x => (
                                <DescriptionRadioButton
                                    description={x.description}
                                    src={x.src}
                                    alt={x.alt}
                                    onClick={x.onClick}
                                    title={x.title}
                                    className={x.className}
                                    key={x.id}
                                />
                            ))}
                        </QuestionnaireButtonRow>

                        <WizardContent.Between
                            content={content}
                            error={wizardContentError}
                            isLoading={wizardContentIsFetching}
                        />
                        <WizardContent.After
                            content={content}
                            error={wizardContentError}
                            isLoading={wizardContentIsFetching}
                        />
                        <ProgressButton isNextDisabled={disableNext} />
                        <SaveAndContinue />
                    </div>
                </Form>
            </section>
        </>
    );
};

export default Ready;
