import axios from 'axios';

import { ReactNode, useCallback, useRef, useState } from 'react';
import FormField from './FormField';

import useFieldValidation from '../../hooks/useFieldValidation';
import { FormInputProps } from '../../types/shared/FormInputProps';

import { useApi } from '../../utils/api';
import { S3_URL } from '../../config';

interface Props extends Omit<FormInputProps<string[] | null>, 'value'> {
    maxSize?: number;
    maxNumberOfFiles?: number;
    position?: 'left' | 'right' | 'center';
    label?: string;
    values: string[];
    buttons?: FileUploadButtonsProps;
}

export interface FileUploadButtonsProps {
    choose?: ReactNode;
    add?: ReactNode;
    clear?: ReactNode;
}

const MultiFileUpload: React.FC<Props> = ({
    name,
    values,
    required = false,
    disabled,
    customValidate,
    onChange,
    maxSize = 5, // Max file size in MB.
    maxNumberOfFiles = 10,
    position = 'center',
    label,
    buttons = { choose: 'Choose', add: 'Add', clear: 'Clear' },
}) => {
    const { choose = 'Choose', add = 'Add', clear = 'Clear' } = buttons;
    const memoizedValidate = useCallback(_validate, []);
    const [internalValue, setInternalValue] = useState<string>(''); // used to allow multiple of the same image to upload
    const [fieldError, showError] = useFieldValidation<string[] | null>({
        name,
        required,
        value: values,
        customValidate,
        extendedValidate: memoizedValidate,
    });
    const api = useApi();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [isPosting, setIsPosting] = useState(false);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const error = uploadError || fieldError;

    const handleClear = useCallback(
        (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
            const newValues = [...values];
            newValues.splice(index, 1);
            setUploadError(null);
            onChange(name, newValues);
            showError();
            setInternalValue('');
        },
        [name, onChange, showError, values],
    );
    const requestMediaURL = useCallback(
        (fileName: string, contentType: string, size: number) => {
            const postBody = { fileName, contentType, size };

            return api.post<typeof postBody, PresignedUrlResponse>(
                `media/get-presigned-url`,
                postBody,
            );
        },
        [api],
    );

    const uploadFile = useCallback(
        async (file: File) => {
            const tidyFileName = makeTidyFileName(file.name);
            const response = await requestMediaURL(tidyFileName, file.type, file.size);
            const { s3Key, s3UploadURL } = response;
            const options = {
                headers: { 'Content-Type': file.type },
            };
            await axios.put(s3UploadURL, file, options);
            return s3Key;
        },
        [requestMediaURL],
    );

    const handleChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            setInternalValue(e.target.value);
            if (!e.target.value) return;
            if ((e.target.files?.length ?? 0) + values.length > maxNumberOfFiles) {
                setUploadError(`You cannot upload more than ${maxNumberOfFiles} files.`);
                return;
            }
            setUploadError(null);
            setIsPosting(true);

            try {
                const files = e.target.files;
                if (!files || !files.length) return;

                const validKeys = [...values];
                for (const file of files) {
                    if (bytesToMB(file.size) > maxSize) {
                        setUploadError(`You cannot upload a file larger than ${maxSize}MB.`);
                        continue;
                    }

                    const s3Key = await uploadFile(file);
                    validKeys.push(s3Key);
                }
                onChange(name, validKeys);
            } catch (err) {
                setUploadError('There was an error uploading your file.');
            }

            showError();
            setIsPosting(false);
            setInternalValue('');
        },
        [maxNumberOfFiles, maxSize, name, onChange, showError, uploadFile, values],
    );

    const handleButtonClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index?: number) => {
            e.preventDefault();
            inputRef.current?.click();
        },
        [],
    );

    return (
        <FormField label={label} name={name} required={required}>
            <div
                className={`single-image-upload-container position-${position} ${
                    error ? 'error' : ''
                }`}
            >
                <div className="single-image-upload">
                    <input
                        ref={inputRef}
                        type="file"
                        className="hidden"
                        name={name}
                        id={name}
                        value={internalValue}
                        multiple
                        onChange={handleChange}
                        accept="image/*"
                        disabled={disabled}
                        aria-label="Browse for an image"
                        aria-required={required ? 'true' : 'false'}
                    />

                    {!values.length && (
                        <button
                            disabled={disabled || isPosting}
                            onClick={handleButtonClick}
                            type="button"
                            className="button primary"
                        >
                            {choose}
                            {isPosting && <i className="icon far fa-fw fa-spinner fa-spin"></i>}
                        </button>
                    )}
                    {values.map((value, key) => (
                        <div className="flex-column justify-center align-center" key={key}>
                            <label htmlFor={name + key}>
                                {!!values &&
                                    (checkIsFilenameImage(value) ? (
                                        <img
                                            className="single-image-preview"
                                            alt="preview"
                                            src={`${S3_URL}/${value}`}
                                        />
                                    ) : (
                                        <>{getFileNameFromS3Key(value)}</>
                                    ))}

                                {values && values.length < 0 && (
                                    <button
                                        disabled={disabled || isPosting}
                                        onClick={e => handleButtonClick(e, key)}
                                        type="button"
                                        className="button primary"
                                    >
                                        {choose}
                                        {isPosting && (
                                            <i className="icon far fa-fw fa-spinner fa-spin"></i>
                                        )}
                                    </button>
                                )}
                            </label>
                            {!!value && (
                                <button
                                    disabled={disabled || isPosting}
                                    onClick={e => handleClear(e, key)}
                                    type="button"
                                    className="button secondary"
                                >
                                    {clear}
                                </button>
                            )}
                        </div>
                    ))}
                </div>

                {values && values.length > 0 && (
                    <button
                        disabled={values.length >= maxNumberOfFiles || disabled || isPosting}
                        onClick={e => handleButtonClick(e)}
                        style={{ width: 'auto' }}
                        type="button"
                        className="button primary"
                    >
                        {add}
                        {isPosting && <i className="icon far fa-fw fa-spinner fa-spin"></i>}
                    </button>
                )}
                <p className="form-error">{error}</p>
            </div>
        </FormField>
    );

    function _validate(val: string[] | null) {}
};

interface PresignedUrlResponse {
    s3UploadURL: string;
    s3Key: string;
}

function makeTidyFileName(filename = '') {
    const tidyName = filename
        .trim()
        .replace(/[^a-zA-Z0-9 .]/g, '')
        .replace(/[ ]/g, '-')
        .toLowerCase();

    return tidyName;
}

function getFileNameFromS3Key(key: string) {
    const name = key?.split('/')?.pop() ?? '';
    return name;
}

function checkIsFilenameImage(name: string | undefined) {
    const isImage = /heif|heic|jpg|jpeg|png|gif|webp/.test(name?.toLowerCase()?.trim() ?? '');
    return isImage;
}

function bytesToMB(bytes: number) {
    return bytes / 1024 / 1024;
}

export default MultiFileUpload;
