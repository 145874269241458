import { ComponentProps, ReactNode, memo, useMemo } from 'react';

const settings = {
    pointsGenerated: 50,
};

const scale = 1;
const topPoint = 100;

const TopBanner = ({
    direction = 'top',
    fill,
    style = {},
    className = '',
    children = <></>,
    ...extraProps
}: TopBannerProps) => {
    const objectPoints = useMemo(() => {
        const { pointsGenerated } = settings;
        const topPointAlt = pointsGenerated / topPoint;
        const elements = [...Array(pointsGenerated + 1)].map((_, i) => {
            const x = (i / pointsGenerated) * topPoint;
            const extra = (((2 * Math.PI) / topPointAlt) * i) / topPoint;
            const y = (-Math.cos(extra) * topPoint + topPoint) * 0.5;
            return {
                x,
                y,
            };
        });
        const extraElements = [
            { x: 0, y: topPoint * scale + 1 },
            ...elements,
            { x: topPoint, y: topPoint * scale + 1 },
        ].map(({ x, y }) => ({
            x,
            y: y + topPoint * 0.25,
        }));

        const polyline = extraElements.map(({ x, y }) => `${x},${y}`).join(' ');
        return polyline;
    }, []);

    const viewport = {
        width: 100 / 3,
        height: 75,
    };

    const start = {
        x: 100 / 3,
        y: 50,
    };

    return (
        <div className={`banner-${direction} ${className}`} {...extraProps}>
            <div className="width-12 height-12 banner-backdrop text-center">
                <div className="flex-column justify-end align-center width-12 height-12 content">
                    {children}
                </div>
            </div>
            <svg
                viewBox={`${start.x} ${start.y} ${viewport.width} ${viewport.height}`}
                preserveAspectRatio="none"
                style={{ ...(fill && { fill }) }}
            >
                <polyline points={objectPoints} strokeLinejoin="round"></polyline>
            </svg>
        </div>
    );
};

type TopBannerProps = {
    direction?: 'top' | 'bottom';
    children?: ReactNode;
    fill?: string;
} & ComponentProps<'div'>;

export default memo(TopBanner);
