import { useContext } from 'react';
import useForm from 'lib/src/hooks/useForm';
import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useApi } from 'lib/src/utils/api';
import { Order } from 'lib/src/types/shared/app/Order';
import { WIZARD_STAGE } from 'lib/src/constants/enums';

type PostAnythingElseRequest = {
    notes: string;
    imageS3Keys: string[];
};

const useAnythingElse = () => {
    const api = useApi();

    const textMaxLength = 1500;
    const {
        postStep,
        error,
        isPosting,
        order,
        bed,
        wizardContent: { content: allContent, ...otherWizardContentProps },
    } = useContext(QuestionnaireContext);

    const content = allContent[WIZARD_STAGE.ANYTHING_ELSE];

    const [form, handleChange] = useForm<PostAnythingElseRequest>({
        notes: bed?.notes ?? '',
        imageS3Keys: bed?.images.map(x => x.s3Key) ?? [],
    });

    const disableNext = form.notes.length >= textMaxLength;

    const handleSubmit = () => {
        const postAnythingElse = () =>
            api.post<PostAnythingElseRequest, Order>(
                `orders/${order?.guid}/beds/${bed?.id}/stage/anythingelse`,
                {
                    notes: form.notes,
                    imageS3Keys: form.imageS3Keys,
                },
            );
        postStep(postAnythingElse);
    };

    return {
        form,
        handleSubmit,
        handleChange,
        isPosting,
        textMaxLength,
        disableNext,
        error,
        content: { content, ...otherWizardContentProps },
    };
};

export default useAnythingElse;
