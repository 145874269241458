import { getTokenFromStorage } from '../utils/generic';
import { useJwt, validateJWT } from '../utils/jwt';
import { atomWithStorage } from 'jotai/utils';

export const jwtAtom = atomWithStorage<string | null>('jwt', getTokenFromStorage('jwt'));
export const refreshTokenAtom = atomWithStorage<string | null>(
    'refreshToken',
    getTokenFromStorage('refreshToken'),
);

export default function useIsLoggedIn(): boolean {
    const jwt = useJwt();
    return validateJWT(jwt);
}
