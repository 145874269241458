import { Tooltip } from 'react-tooltip';
import { S3_URL } from '../../../../config';
import { GardenListStyle } from './hooks/useStyle';
import DescriptionRadioButton from '@components/questionnaire/buttons/DescriptionRadioButton';

const GardenStyleOption = ({
    gardenStyle: { id, description, name, isDisabled, imageS3Keys },
    changeStyle,
    activeStyleId = -1,
}: GardenStyleOptionProps) => {
    const isActive = id === activeStyleId;
    const imageS3Key = imageS3Keys[0];
    return (
        <>
            <div
                id={`style-${id}`}
                className={`flex-column style-box ${isDisabled ? 'disabled' : ''}`}
            >
                <DescriptionRadioButton
                    className={`${isActive ? 'active' : ''}`}
                    description={description}
                    title={name}
                    onClick={() => changeStyle(id)}
                    src={`${S3_URL}/${imageS3Key}`}
                    alt={name}
                />
            </div>

            {isDisabled && (
                <Tooltip anchorSelect={`#style-${id}`} float className="style-tooltip">
                    This style is disabled because it wont work well with your bed.
                </Tooltip>
            )}
        </>
    );
};

type GardenStyleOptionProps = {
    gardenStyle: GardenListStyle;
    activeStyleId: number | null;
    changeStyle: (value: number) => void;
};

export default GardenStyleOption;
