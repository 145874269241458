import Heading from '@components/headings/Heading';
import Description, { DescriptionProps } from 'lib/src/components/typography/Description';
import { WizardContentItem } from 'lib/src/types/shared/app/WizardContent';
import { FC, ReactNode } from 'react';
import { S3_URL } from '../../../config';

const TextImageSplit = ({
    title,
    children,
    nonDescriptionChildren = <></>,
    src = '/images/Pots.webp',
    alt = 'Pots',
    headingComponent: HeadingComponent = Heading.Three,
    heading,
    containerClassName = '',
    ...descriptionProps
}: TextImageSplitProps) => {
    return (
        <div className={`text-image-split flex-row gap-3 justify-center ${containerClassName}`}>
            <div className="flex-column justify-center width-12">
                {heading && <HeadingComponent>{heading}</HeadingComponent>}
                <Description {...descriptionProps}>{children}</Description>
                {nonDescriptionChildren}
            </div>
            <img src={src} alt={alt} className="width-4"></img>
        </div>
    );
};

TextImageSplit.Wizard = ({ imageS3Key, mainText, secondaryText }: WizardContentItem) => (
    <TextImageSplit heading={mainText} src={`${S3_URL}/${imageS3Key}`}>
        {secondaryText}
    </TextImageSplit>
);

interface TextImageSplitProps extends Omit<DescriptionProps, 'children'> {
    children?: ReactNode;
    headingComponent?: FC<{ children: ReactNode }>;
    heading: string;
    containerClassName?: string;
    nonDescriptionChildren?: ReactNode;
    src?: string;
    alt?: string;
}

export default TextImageSplit;
