import { ComponentProps } from 'react';

const ButtonRow: React.FC<ButtonRowProps> = ({
    children,
    alignment = 'center',
    className = '',
    ...otherProps
}) => (
    <div {...otherProps} className={`button-row ${alignment} ${className}`}>
        {children}
    </div>
);

type ButtonRowProps = {
    children: React.ReactNode;
    alignment?: string;
} & ComponentProps<'div'>;

export default ButtonRow;
