import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';

const AccordionContent = ({ children }: AccordionContentProps) => {
    return <Accordion.Content className="content">{children}</Accordion.Content>;
};

interface AccordionContentProps {
    children: React.ReactNode;
}

export default AccordionContent;
