import { BedType } from 'lib/src/types/shared/app/GardenStyle';
import { useMemo } from 'react';
import { withKeys } from 'lib/src/shared/enums/dropdownEnums';
import { BED } from 'lib/src/constants/enums';

const { FULL_BED, SOIL_BED, NO_BEDS } = BED;

const useBedContent = (bedType: BedType | null, bedSelection: (bed: BedType) => void) => {
    const bedContent = useMemo<BedContent[]>(
        () =>
            withKeys(
                [
                    {
                        className: `${bedType === FULL_BED ? 'active' : ''}`,
                        title: 'Full Bed',
                        type: FULL_BED,
                        description: 'I have a combination of plants, weeds and bare patches.',
                        onClick: () => bedSelection(FULL_BED),
                        src: '/images/drawings/17.webp',
                        alt: 'full-bed',
                        preparationSteps: withKeys(
                            [
                                {
                                    text: 'Start by filling your bed with quality soil. This is crucial for plant health and growth.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Plant your chosen flowers. Be sure to space them properly to allow room for growth.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Ensure your flowers receive enough water and sunlight. Balance is key - not too much, and not too little.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Enjoy the process! Monitor your plant growth regularly, making adjustments as necessary.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                            ],
                            'id',
                        ),
                    },
                    {
                        className: `${bedType === SOIL_BED ? 'active' : ''}`,
                        title: 'Soil Bed',
                        type: SOIL_BED,
                        description: 'I have a new or recently cleared, empty bed.',
                        onClick: () => bedSelection(SOIL_BED),
                        src: '/images/drawings/21.webp',
                        alt: 'soil-bed',
                        preparationSteps: withKeys(
                            [
                                {
                                    text: 'Soil bed. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac molestie metus.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Soil bed. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac molestie metus.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Soil bed. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac molestie metus.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'Soil bed. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac molestie metus.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                            ],
                            'id',
                        ),
                    },
                    {
                        className: `${bedType === NO_BEDS ? 'active' : ''}`,
                        title: 'No Bed',
                        type: NO_BEDS,
                        description: 'I know where I want a bed, but it doesn’t exist yet.',
                        onClick: () => bedSelection(NO_BEDS),
                        src: '/images/drawings/15.webp',
                        alt: 'no-beds',
                        preparationSteps: withKeys(
                            [
                                {
                                    text: 'No beds. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac molestie metus.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'No beds. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac molestie metus.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'No beds. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac molestie metus.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                                {
                                    text: 'No beds. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac molestie metus.',
                                    src: '/images/Pots.webp',
                                    alt: 'Pots',
                                },
                            ],
                            'id',
                        ),
                    },
                ],
                'id',
            ),
        [bedType, bedSelection],
    );

    return { bedContent };
};

export type PreparationStep = {
    text: string;
    src: string;
    alt: string;
    id: number;
};

export type BedContent = {
    id: number;
    className: string;
    title: string;
    type: BedType;
    description: string;
    onClick: () => void;
    preparationSteps: PreparationStep[];
    src?: string;
    alt?: string;
};

export default useBedContent;
